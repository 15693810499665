import styled from 'styled-components';

export const Container = styled.div`
  margin: 3rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;

  svg {
    width: 10rem;
    height: 10rem;
    fill: #e3e3e3;
  }
`;

export const Title = styled.h6`
  margin: 3rem 0 1rem;
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
  text-align: center;
`;

export const Subtitle = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  text-align: center;
  line-height: 1.8rem;
`;
