import {
  useListening,
  ListeningSearch,
  ListeningFilter,
  ListeningPreviousSearches,
  ListeningFilterSortByButton,
} from 'features/Listening';
import { t } from 'i18next';
import { ListeningSortByField } from 'features/Listening/types';

import * as S from './Styles';

const Listening = () => {
  const listeningHook = useListening();

  const { countryCode, setCountryCode } = listeningHook;

  return (
    <S.MainContainer>
      <S.ContainerWrap>
        <S.ListeningHeadSection>
          <S.ListeningTitle>{t('listening.title')}</S.ListeningTitle>

          <ListeningSearch customHook={listeningHook} />

          <S.ListeningOptions>
            <S.FilterTags>
              <S.Tag
                active={countryCode === 'PT'}
                onClick={() => setCountryCode('PT')}
              >
                {t('countryCode.PT')}
              </S.Tag>

              <S.Tag
                active={countryCode === 'BR'}
                onClick={() => setCountryCode('BR')}
              >
                {t('countryCode.BR')}
              </S.Tag>
            </S.FilterTags>

            <S.FilterOptions>
              <ListeningFilterSortByButton
                customHook={listeningHook}
                sortByValues={ListeningSortByField}
              />

              <ListeningFilter customHook={listeningHook} />
            </S.FilterOptions>
          </S.ListeningOptions>
        </S.ListeningHeadSection>

        <ListeningPreviousSearches customHook={listeningHook} />
      </S.ContainerWrap>
    </S.MainContainer>
  );
};

export default Listening;
