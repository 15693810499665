export enum NotificationFilter {
  ALL = 'ALL',
  READ = 'READ',
  UNREAD = 'UNREAD',
}

export enum NotificationDropdownOptions {
  MARK_ALL = 'MARK_ALL',
  REMOVE_ALL = 'REMOVE_ALL',
  SETTINGS = 'SETTINGS',
}

export enum NotificationType {
  TOKEN_EXPIRED = 'TOKEN_EXPIRED',
  GENERIC = 'GENERIC',
  PUBLIC_PROFILE_SYNC = 'PUBLIC_PROFILE_SYNC',
  REQUEST_USER_EMAIL = 'REQUEST_USER_EMAIL',
  NEW_RELEASE = 'NEW_RELEASE',
  USER_REFERRED_SUCCESSFULLY = 'USER_REFERRED_SUCCESSFULLY',
  SOCIAL_ACCOUNT_PROCESSED = 'SOCIAL_ACCOUNT_PROCESSED',
  MANAGER_DATA_SHARE_REVOKE = 'MANAGER_DATA_SHARE_REVOKE',
  INFLUENCER_DATA_SHARE_REVOKE = 'INFLUENCER_DATA_SHARE_REVOKE',
  ALLOW_DATA_SHARE = 'ALLOW_DATA_SHARE',
}

export type Notification = {
  id: string;
  type: NotificationType;
  sticky: boolean;
  title: string;
  message: string;
  updatedAt: string;
  createdAt: string;
  metadata?: {
    img?: string;
  };
  deviceToken: string;
  readAt: string | null;
  dismissedAt: string | null;
  deletedAt: string | null;
};

export enum NotificationSettingsType {
  'PUSH_NOTIFICATION' = 'PUSH_NOTIFICATION',
  'NOTIFICATION' = 'NOTIFICATION',
  'WEEK_EMAIL' = 'WEEK_EMAIL',
  'EMAIL' = 'EMAIL',
}

export enum NotificationSettingsScope {
  'NOTIFICATION' = 'NOTIFICATION',
  'EMAIL' = 'EMAIL',
}

export type NotificationSettings = {
  pushNotification: boolean;
};

export type RawNotificationSettings = {
  userId: string;
  scope: NotificationSettingsScope;
  type: NotificationSettingsType;
  active: boolean;
};
