/* eslint-disable default-case */
import { t } from 'i18next';
import { Trans } from 'react-i18next';
import { useTheme } from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { useEffect, useRef, useState } from 'react';
import { SocialNetworks } from 'Services/Utils/types';
import { useGetPublicUserBasicInformationWithExternalProvider } from 'pages/SocialAccountsManagement/RQCustomHooks';
import Loader from 'react-loader-advanced';
import InfoV2Icon from 'assets/iconComponents/InfoIconV2';
import TiktokIcon from 'assets/iconComponents/TiktokIcon';
import YoutubeIcon from 'assets/iconComponents/YoutubeIcon';
import { StyledButton } from 'components/shared/Button/Styles';
import BackArrowIcon from 'assets/iconComponents/BackArrowIcon';
import CheckMarkIcon from 'assets/iconComponents/CheckMarkIcon';
import InstagramIcon from 'assets/iconComponents/InstagramIcon';
import GenericModal from 'components/shared/GenericModal/GenericModal';
import NotifyCustom from 'components/shared/NotifyCustom/NotifyCustom';
import InputControlV2 from 'components/shared/InputControlV2/InputControlV2';

import * as S from './Styles';

const RequestSocialAccount = () => {
  const navigate = useNavigate();
  const themeGlobal = useTheme();

  const socialAccountUsernameFieldRef = useRef();

  const [hasError, setHasError] = useState(false);
  const [msgError, setMsgError] = useState('');
  const [username, setUsername] = useState('');
  const [socialNetworkSelected, setSocialNetworkSelected] = useState<
    SocialNetworks | undefined
  >();
  const [
    isGetPublicUserBasicInformationEnabled,
    setIsGetPublicUserBasicInformationEnabled,
  ] = useState(false);
  const [
    showGetPublicUserBasicInformation,
    setShowGetPublicUserBasicInformation,
  ] = useState(false);

  const isUsernameValid = () => {
    const usernameTrimmed = (username || '').trim().toLocaleLowerCase();

    const re = /^(?=.*[a-zA-Z0-9])[a-zA-Z0-9._]+$/;

    return (
      re.test(usernameTrimmed) &&
      usernameTrimmed.length &&
      !usernameTrimmed.includes(' ')
    );
  };

  const isAddButtonDisabled = () => {
    return Boolean(!(username && socialNetworkSelected) || !isUsernameValid());
  };

  const addButtonClicked = () => {
    setIsGetPublicUserBasicInformationEnabled(true);
  };

  const socialNetworkInputKeyUpHandler = (event: any) => {
    if (event.keyCode === 13 && !isAddButtonDisabled()) {
      addButtonClicked();
    }
  };

  const connectNetworkButtonClicked = (socialNetwork: SocialNetworks) => {
    switch (socialNetwork) {
      case SocialNetworks.INSTAGRAM: {
        if (socialNetworkSelected !== SocialNetworks.INSTAGRAM) {
          setSocialNetworkSelected(SocialNetworks.INSTAGRAM);
        } else {
          setSocialNetworkSelected(undefined);
        }
        break;
      }
      case SocialNetworks.YOUTUBE: {
        if (socialNetworkSelected !== SocialNetworks.YOUTUBE) {
          setSocialNetworkSelected(SocialNetworks.YOUTUBE);
        } else {
          setSocialNetworkSelected(undefined);
        }
      }
    }
  };

  const getUsernameInputHelperText = () => {
    switch (socialNetworkSelected) {
      case SocialNetworks.INSTAGRAM: {
        return (
          <Trans i18nKey="socialAccountManagement.addSocialAccounts.usernameInputHelperMessageInstagram" />
        );
      }
      case SocialNetworks.YOUTUBE: {
        return (
          <Trans i18nKey="socialAccountManagement.addSocialAccounts.usernameInputHelperMessageYoutube" />
        );
      }
      default: {
        return '';
      }
    }
  };

  const errorModalCloseButtonClicked = () => {
    setHasError(false);
  };

  const {
    data: publicUserBasicInformation,
    isFetching: isFetchingGetPublicUserBasicInformation,
    isSuccess: isSuccessGetPublicUserBasicInformation,
    isError: isErrorGetPublicUserBasicInformation,
  } = useGetPublicUserBasicInformationWithExternalProvider({
    enabled: isGetPublicUserBasicInformationEnabled,
    params: {
      username: username.trim().replace('@', '').toLocaleLowerCase(),
      socialNetwork: socialNetworkSelected,
    },
    setHasError,
    setMsgError,
    t,
  });

  useEffect(() => {
    if (
      isSuccessGetPublicUserBasicInformation &&
      isGetPublicUserBasicInformationEnabled &&
      Object.values(publicUserBasicInformation).length
    ) {
      setShowGetPublicUserBasicInformation(true);

      setTimeout(() => {
        setShowGetPublicUserBasicInformation(false);
      }, 2000);

      setIsGetPublicUserBasicInformationEnabled(false);

      setUsername('');
    }
  }, [
    isSuccessGetPublicUserBasicInformation,
    isGetPublicUserBasicInformationEnabled,
    publicUserBasicInformation,
  ]);

  useEffect(() => {
    if (isErrorGetPublicUserBasicInformation) {
      setIsGetPublicUserBasicInformationEnabled(false);
    }
  }, [isErrorGetPublicUserBasicInformation]);

  return (
    <S.Container>
      <Loader
        show={isFetchingGetPublicUserBasicInformation}
        message={t(
          'socialAccountManagement.addSocialAccounts.getting-social-account-data',
        )}
      />

      <S.InnerContainer>
        <S.Card>
          <S.HeaderOuterContainer>
            <S.HeaderContainer>
              <S.HeaderBackArrowContainer
                onClick={() => {
                  navigate(-1);
                }}
              >
                <BackArrowIcon width={'4rem'} height={'4rem'} />
              </S.HeaderBackArrowContainer>
              <S.HeaderTitleContainer>
                {t('requestSocialAccounts.title')}
              </S.HeaderTitleContainer>
              <S.HiddenHeaderElement />
            </S.HeaderContainer>
          </S.HeaderOuterContainer>

          <S.CardInnerContainer>
            <S.AddSocialAccountContainer
              onKeyUp={event => socialNetworkInputKeyUpHandler(event)}
            >
              <S.Title>{t('requestSocialAccounts.subtitle')}</S.Title>

              <S.ConnectSocialAccountButtonsContainer>
                <S.ConnectInstagramAccountButton
                  disabled={socialNetworkSelected === SocialNetworks.YOUTUBE}
                  onClick={() =>
                    connectNetworkButtonClicked(SocialNetworks.INSTAGRAM)
                  }
                >
                  <InstagramIcon
                    containerWidth="2rem"
                    containerHeight="2rem"
                    noAssignColor={true}
                  />
                  {t('requestSocialAccounts.button.instagram')}
                  <S.HiddenElement className="hidden-element" />
                </S.ConnectInstagramAccountButton>

                <S.ConnectYoutubeAccountButton
                  disabled={socialNetworkSelected === SocialNetworks.INSTAGRAM}
                  onClick={() =>
                    connectNetworkButtonClicked(SocialNetworks.YOUTUBE)
                  }
                >
                  <YoutubeIcon
                    containerWidth="2rem"
                    containerHeight="2rem"
                    noAssignColor={true}
                  />
                  {t('requestSocialAccounts.button.youtube')}
                  <S.HiddenElement className="hidden-element" />
                </S.ConnectYoutubeAccountButton>

                <S.ConnectTiktokAccountButton disabled={true}>
                  <TiktokIcon
                    containerWidth="2rem"
                    containerHeight="2rem"
                    disabledColored={true}
                  />
                  {t('requestSocialAccounts.button.tiktok')}
                  <S.HiddenElement className="hidden-element" />
                  <S.TiktokSoonContainer>{'soon'}</S.TiktokSoonContainer>
                </S.ConnectTiktokAccountButton>
              </S.ConnectSocialAccountButtonsContainer>

              <S.AddSocialAccountsInnerContainer
                visible={socialNetworkSelected !== undefined}
              >
                <S.UsernameInputContainer>
                  <InputControlV2
                    value={username}
                    onChange={(inputValue: string) => setUsername(inputValue)}
                    refNotNative={socialAccountUsernameFieldRef}
                    title={t(
                      'socialAccountManagement.addSocialAccounts.usernameInputTitle',
                    )}
                    inputFieldContainerWithTitleWidth={'100%'}
                    placeholder={t(
                      'socialAccountManagement.addSocialAccounts.usernameInputPlaceholder',
                    )}
                    inputTitleMarginBottom={'1.2rem'}
                    inputFieldContainerMarginBottom={'1.4rem'}
                  />
                  <S.UsernameInputInformationContainer>
                    <S.UsernameInputInfoIconContainer>
                      <InfoV2Icon
                        svgWidth="2.7rem"
                        svgHeight="2.7rem"
                        containerMarginLeft="1rem"
                        containerMarginRight="1rem"
                        noAssignColor={true}
                      />
                    </S.UsernameInputInfoIconContainer>
                    <S.UsernameInputInformationInnerContainer>
                      {getUsernameInputHelperText()}
                    </S.UsernameInputInformationInnerContainer>
                  </S.UsernameInputInformationContainer>
                </S.UsernameInputContainer>

                <S.AddSocialAccountButtonContainer>
                  <StyledButton
                    borderRadius={'0.6rem'}
                    borderWidth={'0rem'}
                    height={'4rem'}
                    fontSize={'1.6rem'}
                    textAlign={'center'}
                    fontWeight={'500'}
                    themeStyles={
                      (themeGlobal as any).createExternalMediaKitConfiguration
                        .createButton
                    }
                    disabled={isAddButtonDisabled()}
                    onClick={() => addButtonClicked()}
                  >
                    {t('requestSocialAccounts.button.request')}
                  </StyledButton>
                </S.AddSocialAccountButtonContainer>
              </S.AddSocialAccountsInnerContainer>
            </S.AddSocialAccountContainer>
          </S.CardInnerContainer>
        </S.Card>
      </S.InnerContainer>

      <GenericModal
        show={hasError}
        title={t('generic.uhoh')}
        body={msgError}
        primaryButtonText={t('generic.close')}
        primaryButtonAction={() => {
          errorModalCloseButtonClicked();
        }}
      />

      <NotifyCustom
        location={'top'}
        show={showGetPublicUserBasicInformation}
        themeStyles={(themeGlobal as any).signin['success-notify']}
        topInitialOffset={'5rem'}
      >
        <S.NotifyInnerContainer>
          <CheckMarkIcon
            containerWidth={'2rem'}
            containerHeight={'2rem'}
            containerMarginRight={'1rem'}
            useCase={'success-notify'}
          />
          {'The social account you requested will be available in a moment'}
        </S.NotifyInnerContainer>
      </NotifyCustom>
    </S.Container>
  );
};

export default RequestSocialAccount;
