import { t } from 'i18next';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import Button from 'components/shared/Button/Button';

import * as S from './Styles';

const DiscoverEmptyState = () => {
  const navigate = useNavigate();

  const handleGoToSocialAccounts = useCallback(() => {
    navigate('/request-social-account');
  }, [navigate]);

  return (
    <S.EmptyState>
      <S.EmptyStateIcon>
        <svg viewBox="0 0 32 32">
          <path
            d="M16.051 28.349c-6.919-0.023-12.378-5.53-12.373-12.357 0.005-6.884 5.522-12.372 12.383-12.34 6.838 0.032 12.269 5.534 12.261 12.35-0.008 6.899-5.513 12.286-12.271 12.348zM15.982 19.242c0 0 0 0.001 0 0.001 0.296 0 0.592 0.001 0.889-0 0.729-0.003 0.66 0.088 0.727-0.688 0.119-1.358 0.197-2.72 0.29-4.081 0.058-0.841 0.11-1.683 0.172-2.524 0.041-0.563 0.088-1.127 0.144-1.689 0.061-0.616 0.133-1.226-0.151-1.817-0.505-1.053-1.489-1.487-2.54-1.274-1.087 0.22-1.794 1.18-1.768 2.301 0.011 0.502 0.069 1.002 0.104 1.503 0.043 0.618 0.081 1.236 0.128 1.853 0.098 1.297 0.201 2.593 0.301 3.89 0.057 0.742 0.111 1.485 0.167 2.228 0.015 0.201 0.106 0.306 0.326 0.3 0.404-0.011 0.808-0.003 1.212-0.003zM14.157 23.027c-0.066 0.985 0.788 1.851 1.799 1.863 1.109 0.014 1.874-0.861 1.895-1.836 0.023-1.028-0.84-1.866-1.815-1.891-0.996-0.026-1.957 0.847-1.879 1.864z"
            fill="#BFBFBF"
          />
        </svg>
      </S.EmptyStateIcon>

      <S.EmptyStateTitle>{t('discover.emptyState.title')}</S.EmptyStateTitle>

      <S.EmptyStateDescription>
        {t('discover.emptyState.description')}
      </S.EmptyStateDescription>

      <S.EmptyStateOrText>{t('discover.emptyState.or')}</S.EmptyStateOrText>

      <Button onClick={handleGoToSocialAccounts} borderRadius="0.6rem">
        {t('discover.emptyState.button')}
      </Button>
    </S.EmptyState>
  );
};

export default DiscoverEmptyState;
