import styled from 'styled-components';

export const MainContainer = styled.div`
  display: flex;
  padding: 4rem 30rem;
  flex-direction: column;

  @media (max-width: 1720px) {
    padding: 4rem 20rem;
  }

  @media (max-width: 1510px) {
    padding: 4rem 10rem;
  }

  @media (max-width: 1310px) {
    padding: 4rem 4rem;
  }

  @media (max-width: 464px) {
    padding: 4rem 3rem;
  }

  @media (max-width: 444px) {
    padding: 4rem 2rem;
  }

  @media (max-width: 424px) {
    padding: 4rem 1rem;
  }
`;
