export default {
  ENDPOINTS: {
    SKORR_API_BASE_URL: process.env.REACT_APP_API_URL,

    AUTH: {
      SIGN_UP: 'public/v1/partner/signup',
      SIGN_IN: 'public/v1/authentication/signin',
      SELECT_BUSINESS_PROFILE:
        'private/v1/authentication/create-business-profile-token',
      DEACTIVATE_BUSINESS_PROFILE:
        'public/v1/authentication/deactivate-business-profile',
      GET_ROLES: 'private/v1/authorization/get-roles',
      UPDATE_ROLES: 'private/v1/authorization/update-roles',
      IS_OPERATOR_OR_PARTNER_ADMIN:
        'private/v1/authorization/is-operator-or-partner-admin',
      SEND_FORGOT_PASSWORD_CODE_EMAIL: 'public/v1/credentials/forgot-password',
      VERIFY_FORGOT_PASSWORD_CODE:
        'public/v1/credentials/verify-forgot-password-code',
      UPDATE_PASSWORD_WITH_CODE: 'public/v1/credentials/recover-password',
      IS_EMAIL_VERIFIED: 'private/v1/credentials/is-email-verified',
      VERIFY_ACCOUNT_ACTIVATION_CODE:
        'private/v1/credentials/verify-account-activation-code',
      SEND_ACCOUNT_ACTIVATION_CODE_EMAIL:
        'private/v1/credentials/send-account-activation-code-email',
      GET_TOKEN: 'private/v1/authentication/get-token',
      IS_EMAIL_IN_USE: '/public/v1/credentials/is-email-in-use',
      CERTIFY_ACTIVATION_CODE_EMAIL_SENT:
        '/private/v1/credentials/certify-activation-code-email-sent',
      VALIDATE_CAPTCHA_TOKEN:
        '/public/v1/authentication/validate-token-captcha',
    },

    USER: {
      CREATE_BUSINESS_PROFILE: '/private/v1/business-profile/create',
      UPDATE_BUSINESS_PROFILE: '/private/v1/business-profile/update',
      DELETE_BUSINESS_PROFILE: '/private/v1/business-profile/delete-one-by-id',
      GET_BUSINESS_PROFILES:
        '/private/v1/business-profile/get-business-profiles-by-partner-id',
      GET_BUSINESS_PROFILE_BY_ID: '/private/v1/business-profile/get-by-id',
      GET_BUSINESS_PROFILE_BY_PARTNER_ID:
        '/private/v1/business-profile/get-business-profiles-by-partner-id',
      GET_ALREADY_EXISTING_SOCIAL_NETWORK_ACCOUNTS:
        'private/v1/user/get-already-existing-social-network-accounts',
      GET_SOCIAL_TOKENS_BY_USER_ID: '/private/v1/user/get-all-tokens',
      IS_ADDITIONAL_DATA_STEP_COMPLETE:
        'private/v1/partner/is-additional-data-step-complete',
      COMPLETE_ADDITIONAL_DATA_STEP:
        'private/v1/partner/complete-additional-data-step',
      GET_PARTNER_WEBSITE: 'private/v1/partner/get-partner-website',
      IS_AGENT_SKORR_ADMIN: 'private/v1/agent/is-skorr-admin',
      GET_AGENT_ROLE_IDS: 'private/v1/agent/get-agent-role-ids',
      DELETE_PARTNER: 'private/v1/user/delete',
      UPDATE_AGENT: 'private/v1/agent/update',
      UPDATE_AGENT_PASSWORD: 'private/v1/credentials/update-password',
      CREATE_AGENT: 'private/v1/agent/create',
      UPDATE_AGENT_AND_USER: 'private/v1/agent/update-agent-and-user',
      UPDATE_PARTNER:
        'private/v1/partner/update-partner-and-default-business-profile',
      GET_AGENTS_BY_PARTNER_ID: 'private/v1/partner-admin-dashboard/get-agents',
      GET_AGENT_BY_ID: 'private/v1/agent/get-by-id',
      GET_PARTNER_BY_ID: 'private/v1/partner/get-by-id',
      SEND_ACCOUNT_DELETION_EMAIL:
        'public/v1/partner/send-account-deletion-code-email',
      GET_USER_BY_ID: 'private/v1/user/get-by-id',
      UPDATE_ACCOUNT: 'private/v1/partner/update-account',
      GET_PUBLIC_USER_IMAGE_BY_ID:
        'private/v1/public-user/get-public-user-image-by-id',
      GET_PUBLIC_USER_BASIC_INFORMATION_WITH_EXTERNAL_PROVIDER:
        'private/v1/public-user/get-public-user-basic-information',
      UPDATE_USER_PUBLIC_PROFILES: 'private/v1/user/update-public-profiles',
      GET_KPIS_OVERVIEW: 'private/v1/public-user/get-kpis-overview',
      GET_PUBLIC_USERS_BY_ID: 'private/v1/public-user/get-public-user',
      GET_KPIS_OVERVIEW_DAILY: 'private/v1/public-user/get-kpis-overview-daily',
      GET_PUBLIC_USER_AUDIENCE_GENDERS_PER_AGE:
        'private/v1/public-user/get-audience-genders-per-age',
      GET_PUBLIC_USER_AUDIENCE_GENDERS:
        'private/v1/public-user/get-audience-gender',
      GET_PUBLIC_USER_AUDIENCE_GEO:
        'private/v1/public-user/get-audience-demographic',
      GET_PUBLIC_USER_AUDIENCE_TYPES:
        'private/v1/public-user/get-audience-types',
      GET_PUBLIC_USER_AUDIENCE_REACHABILITY:
        'private/v1/public-user/get-audience-reachability',
      GET_PUBLIC_USERS_BY_FILTER: 'private/v1/public-user/get-by-filter',
      CREATE_LIST: 'private/v1/list/create',
      UPDATE_LIST_BY_ID: 'private/v1/list/update-by-id',
      GET_LIST_BY_ID: 'private/v1/list/get-by-id',
      GET_LISTS_BY_PARTNER_AND_BUSINESS_PROFILE:
        'private/v1/list/get-by-partner-and-business-profile',
      GET_LIST_BY_ID_WITH_PUBLIC_USERS:
        'private/v1/list/get-by-id-with-public-users',
      DELETE_LIST_BY_ID: 'private/v1/list/delete-by-id',
      DELETE_PUBLIC_USER_FROM_LIST:
        'private/v1/list/delete-public-user-from-list',
      ADD_PUBLIC_USER_TO_LIST: 'private/v1/list/add-public-user-to-list',
      GET_BENCHMARKS: 'private/v1/benchmark/benchmarks',
      GET_TOTAL_BENCHMARKS: 'private/v1/benchmark/get-total',
      CREATE_BENCHMARK: 'private/v1/benchmark/create',
      UPDATE_BENCHMARK: 'private/v1/benchmark/update',
      DELETE_BENCHMARK: 'private/v1/benchmark/delete',
      SEARCH_USER_BENCHMARK: 'private/v1/benchmark/search-user',
      GET_FACEBOOK_ACCOUNTS_TO_SYNC:
        'private/v1/social-network/get-facebook-accounts-to-sync',
      GET_TIKTOK_ACCOUNTS_TO_SYNC:
        'private/v1/social-network/get-tiktok-account-to-sync',
      UPDATE_SYNC_USERS: 'private/v1/user/update-sync-users',

      PARTNER_ADMIN_DASHBOARD: {
        GET_AGENTS: 'private/v1/partner-admin-dashboard/get-agents',
        ACTIVATE_AGENT: 'private/v1/partner-admin-dashboard/activate-agent',
        DEACTIVATE_AGENT: 'private/v1/partner-admin-dashboard/deactivate-agent',
        CREATE_AGENT: 'private/v1/partner-admin-dashboard/create-agent',
        DELETE_AGENT: 'private/v1/partner-admin-dashboard/delete-agent',
        GET_AGENT_USER: 'private/v1/partner-admin-dashboard/get-agent-user',
        UPDATE_AGENT: 'private/v1/partner-admin-dashboard/update-agent',
      },
      GET_ADMIN_PARTNER: 'private/v1/skorr-admin/get-partner',
      GET_ADMIN_PARTNERS: 'private/v1/skorr-admin/get-partners',
      CREATE_ADMIN_PARTNER: 'private/v1/skorr-admin/create-partner',
      UPDATE_ADMIN_PARTNER: 'private/v1/skorr-admin/update-partner',
      VALIDATE_ADMIN_PARTNER: 'private/v1/skorr-admin/validate-partner',
      GET_ADMIN_PARTNER_AGENT: 'private/v1/skorr-admin/get-partner-agent',
      GET_ADMIN_PARTNER_AGENTS: 'private/v1/skorr-admin/get-partner-agents',
      CREATE_ADMIN_PARTNER_AGENT: 'private/v1/skorr-admin/create-partner-agent',
      UPDATE_ADMIN_PARTNER_AGENT: 'private/v1/skorr-admin/update-partner-agent',
      VALIDATE_ADMIN_PARTNER_AGENT:
        'private/v1/skorr-admin/validate-partner-agent',
      GET_ADMIN_PARTNER_AGENT_USER:
        'private/v1/skorr-admin/get-partner-agent-user',
    },

    PUBLIC_USER: {
      GET_BY_ID_WITH_PROJECTION:
        '/private/v1/public-user/get-public-user-by-id-with-projection',
    },

    POST: {
      GET_PUBLIC_USER_HASHTAGS_SORTED_BY_METRICS:
        'private/v1/public-post/get-public-user-hashtags-sorted-by-metrics',
      GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID:
        'private/v1/public-post/get-public-posts-by-public-user-id',
      GET_PUBLIC_POSTS_BY_PUBLIC_USER_ID_FILTERED_BY_KEYWORDS:
        'private/v1/public-post/get-public-posts-filtered-by-keywords',
      GET_PUBLIC_POSTS_BY_FILTER:
        'private/v1/public-post/get-public-posts-by-filter',
      GET_PUBLIC_POSTS_KPIS_BY_FILTER:
        'private/v1/public-post/get-public-posts-kpis-by-filter',
      GET_HASHTAGS_BY_FILTER: 'private/v1/public-post/get-hashtags-by-filter',
      GET_EVOLUTION_BY_FILTER: 'private/v1/public-post/get-evolution-by-filter',
      GET_EVOLUTION_GROUPED_BY_KPI:
        'private/v1/public-post/get-evolution-grouped-by-kpi',
    },

    GENERIC: {
      GET_LOCATIONS: 'private/v1/location/get-locations',
      GET_TOPICS_INTEREST: 'private/v1/topics-interest/get-topics-interest',
      GET_ALL_BRAND_PICTURES: 'private/v1/brands/get-all-brand-pictures',
      PUBLIC: {
        GET_TAGS_BY_KEY: 'public/v1/topics-interest/get-tags-by-key',
      },
    },

    EXTERNAL_MEDIA_KIT: {
      GET_EXTERNAL_MEDIA_KIT_CONFIGURATION_BY_ID:
        'private/v1/external-media-kit/get-by-id',
      GET_EXTERNAL_MEDIA_KIT_CONFIGURATIONS_BY_USER_ID:
        'private/v1/external-media-kit/get-by-user-id',
      GENERATE_EXTERNAL_MEDIA_KIT_URL:
        'private/v1/external-media-kit/generate-external-media-kit-url',
      GENERATE_EXTERNAL_PORTFOLIO_URL:
        'private/v1/external-media-kit/generate-external-portfolio-url',
      UPDATE_EXTERNAL_MEDIA_KIT: 'private/v1/external-media-kit/update-by-id',
      GET_PORTFOLIO_BY_BRAND_PICTURE:
        'private/v1/external-media-kit/get-portfolio-by-brand-picture',
      UPLOAD_PORTFOLIO_IMAGE:
        'private/v1/external-media-kit/upload-portfolio-image',
      UPLOAD_USER_BRAND_PICTURE:
        'private/v1/external-media-kit/upload-user-brand-picture',
      DELETE_USER_BRAND_PICTURE:
        'private/v1/external-media-kit/delete-user-brand-picture',
      GET_USER_BRAND_PICTURES:
        'private/v1/external-media-kit/get-user-brand-pictures',
    },

    SHARES: {
      GET_SHARED_SOCIAL_DATA_ACCESS: 'private/v1/social-data-share/',
      REVOKE_SOCIAL_DATA_ACCESS: 'private/v1/social-data-share/revoke',
    },

    FILES: {
      CREATE_CLOUD_URL: 'private/v1/file/send-file',
    },

    EXPORTER: {
      EXPORT_PDF: 'private/v1/exporter/export-pdf',
    },

    NOTIFICATIONS: {
      GET_NOTIFICATIONS: 'private/v1/push-notification/get-push-notifications',
      MARK_AS_READ_BY_ID: 'private/v1/push-notification/mark-as-read',
      DELETE_BY_ID: 'private/v1/push-notification/delete',
      DISMISS_ALL: 'private/v1/push-notification/dismiss-all',
      UPDATE_SETTINGS:
        'private/v1/push-notification/update-notification-settings',
      GET_SETTINGS: 'private/v1/push-notification/get-notification-settings',
      MARK_ALL_AS_READ_NOTIFICATIONS:
        'private/v1/push-notification/mark-all-as-read-notifications',
    },
  },
};
