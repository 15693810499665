import { t } from 'i18next';
import moment from 'moment';
import { toast } from 'react-toastify';
import { IErrorObject } from 'Services/Utils/types';
import { logApiRequestError } from 'Services/Utils/Utils';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { BenchmarkOutput } from '../types';
import { useDeleteBenchmark } from '../api';

export const useViewBenchmark = () => {
  const navigate = useNavigate();

  const divRef = useRef<HTMLDivElement>(null);

  const location = useLocation();
  const { state } = location || {};
  const { benchmarkData }: { benchmarkData: BenchmarkOutput } = state;

  const startDate = moment(
    benchmarkData.benchmarkInfo.startAt ?? moment().subtract(1, 'month'),
  )
    .utc()
    .startOf('day')
    .format('ll');

  const endDate = moment(benchmarkData.benchmarkInfo.endAt ?? moment())
    .utc()
    .endOf('day')
    .format('ll');

  const numDays = moment(endDate).diff(moment(startDate), 'days');

  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);

  const handleConfirmDeleteModal = useCallback(() => {
    setShowConfirmDeleteModal(prevState => !prevState);
  }, []);

  const handleGoToUpdateBenchmark = useCallback(
    (data: BenchmarkOutput) => {
      navigate(`/update-benchmark/${data.benchmarkInfo.id}`, {
        state: {
          benchmarkData: data.benchmarkInfo,
        },
      });
    },
    [navigate],
  );

  const { mutateAsync: deleteBenchmark, isSuccess: isSuccessDeleteBenchmark } =
    useDeleteBenchmark();

  const handleDeleteBenchmark = useCallback(
    async (id: string) => {
      try {
        await deleteBenchmark(id);
      } catch (error) {
        toast.error(logApiRequestError(error as IErrorObject));
      }
      handleConfirmDeleteModal();
    },
    [deleteBenchmark, handleConfirmDeleteModal],
  );

  useEffect(() => {
    if (isSuccessDeleteBenchmark) {
      toast.success(t('benchmark.toast.delete'));

      setTimeout(() => {
        navigate(-1);
      }, 1000);
    }
  }, [isSuccessDeleteBenchmark, navigate]);

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleGoToUserMediaKit = useCallback(
    (id: string) => {
      navigate(`/user-media-kit/${id}`);
    },
    [navigate],
  );

  useEffect(() => {
    divRef.current?.scrollIntoView({
      behavior: 'auto',
      block: 'start',
    });
  }, []);

  return {
    divRef,
    numDays,
    endDate,
    startDate,
    handleGoBack,
    benchmarkData,
    handleDeleteBenchmark,
    showConfirmDeleteModal,
    handleGoToUserMediaKit,
    handleConfirmDeleteModal,
    handleGoToUpdateBenchmark,
  };
};
