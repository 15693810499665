/* eslint-disable consistent-return */
/* eslint-disable import/extensions */
/* eslint-disable eqeqeq */
import styled, { css } from 'styled-components';

import { IContainerProps } from './types';

export const Container = styled.div<IContainerProps>`
  visibility: ${props => (props.show ? 'visible' : 'hidden')};
  opacity: ${props => (props.show ? '1' : '0')};
  position: ${props => {
    if (props.relativeTo == 'window') return 'fixed';
    if (props.relativeTo == 'section') return 'absolute';
    return 'fixed';
  }};
  ${props => {
    if (props.location == 'top' || props.location == undefined) {
      return css`
        top: ${() => {
          if (props.show) {
            if (props.topInitialOffset)
              return `calc(${props.topInitialOffset} + 3rem)`;
            return '3rem';
          }

          if (props.topInitialOffset) return props.topInitialOffset;
          return '0';
        }};
      `;
    }
  }};
  ${props => {
    if (props.location == 'bottom') {
      return css`
        bottom: ${() => {
          if (props.show) {
            if (props.bottomInitialOffset)
              return `calc(${props.bottomInitialOffset} + 3rem)`;
            return '3rem';
          }

          if (props.bottomInitialOffset) return props.bottomInitialOffset;
          return '0';
        }};
      `;
    }
  }};
  left: 50%;
  transform: translateX(-50%);
  display: ${props => (props.display ? props.display : 'block')};
  ${props => {
    if (props.display == 'flex') {
      return css`
        justify-content: ${props.justifyContent
          ? props.justifyContent
          : 'flex-start'};
        align-items: ${props.alignItems ? props.alignItems : 'stretch'};
      `;
    }
  }};
  background-color: ${props => {
    if (props.backgroundColor) return props.backgroundColor;
    if (props.themeStyles && props.themeStyles['background-color'])
      return props.themeStyles['background-color'];
    return 'inherit';
  }};
  font-size: ${props => {
    if (props.fontSize) return props.fontSize;
    if (props.themeStyles && props.themeStyles['font-size'])
      return props.themeStyles['font-size'];
    return 'inherit';
  }};
  font-weight: ${props => {
    if (props.fontWeight) return props.fontWeight;
    if (props.themeStyles && props.themeStyles['font-weight'])
      return props.themeStyles['font-weight'];
    return 'inherit';
  }};
  color: ${props => {
    if (props.color) return props.color;
    if (props.themeStyles && props.themeStyles.color)
      return props.themeStyles.color;
    return 'inherit';
  }};
  padding-top: ${props => {
    if (props.paddingTop) return props.paddingTop;
    if (props.themeStyles && props.themeStyles['padding-top'])
      return props.themeStyles['padding-top'];
    return '0';
  }};
  padding-bottom: ${props => {
    if (props.paddingBottom) return props.paddingBottom;
    if (props.themeStyles && props.themeStyles['padding-bottom'])
      return props.themeStyles['padding-bottom'];
    return '0';
  }};
  padding-left: ${props => {
    if (props.paddingLeft) return props.paddingLeft;
    if (props.themeStyles && props.themeStyles['padding-left'])
      return props.themeStyles['padding-left'];
    return '0';
  }};
  padding-right: ${props => {
    if (props.paddingRight) return props.paddingRight;
    if (props.themeStyles && props.themeStyles['padding-right'])
      return props.themeStyles['padding-right'];
    return '0';
  }};
  border-style: ${props => {
    if (props.borderStyle) return props.borderStyle;
    if (props.themeStyles && props.themeStyles['border-style'])
      return props.themeStyles['border-style'];
    return 'unset';
  }};
  border-width: ${props => {
    if (props.borderWidth) return props.borderWidth;
    if (props.themeStyles && props.themeStyles['border-width'])
      return props.themeStyles['border-width'];
    return 'unset';
  }};
  border-color: ${props => {
    if (props.borderColor) return props.borderColor;
    if (props.themeStyles && props.themeStyles['border-color'])
      return props.themeStyles['border-color'];
    return 'unset';
  }};
  border-radius: ${props => {
    if (props.borderRadius) return props.borderRadius;
    if (props.themeStyles && props.themeStyles['border-radius'])
      return props.themeStyles['border-radius'];
    return 'unset';
  }};
  pointer-events: ${props =>
    props.pointerEvents ? props.pointerEvents : 'all'};
  cursor: ${props => (props.cursor ? props.cursor : 'default')};
  width: ${props => {
    if (props.width) return props.width;
    if (props.themeStyles && props.themeStyles.width)
      return props.themeStyles.width;
    return 'auto';
  }};
  height: ${props => {
    if (props.height) return props.height;
    if (props.themeStyles && props.themeStyles.height)
      return props.themeStyles.height;
    return 'auto';
  }};
  min-width: ${props => {
    if (props.minWidth) return props.minWidth;
    if (props.themeStyles && props.themeStyles['min-width'])
      return props.themeStyles['min-width'];
    return 'auto';
  }};
  text-align: ${props => {
    if (props.textAlign) return props.textAlign;
    if (props.themeStyles && props.themeStyles['text-align'])
      return props.themeStyles['text-align'];
    return 'center';
  }};
  transition: ${props => {
    if (props.transition) return props.transition;
    if (props.themeStyles && props.themeStyles.transition)
      return props.themeStyles.transition;
    return 'all 0.5s ease-in-out';
  }};
  z-index: ${props => (props.show ? '999999999999999999' : '0')};
`;

export const NotifyInnerContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
`;
