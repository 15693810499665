/* eslint-disable no-empty-function */
import {
  NotificationFilter,
  NotificationDropdownOptions,
} from 'features/Notifications/types';
import { t } from 'i18next';
import ToggleButton from 'components/shared/ToggleButton/ToggleButton';
import { notificationDropdownIcons } from 'features/Notifications/data/constants';
import { NotificationsHookProps } from 'features/Notifications/hooks/use-notifications';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

type Props = {
  customHook: NotificationsHookProps;
};

const NotificationsHeaderSection = ({ customHook }: Props) => {
  const {
    handleGoBack,
    notifications,
    toggleDropdown,
    notificationFilter,
    handleCloseDropdown,
    handleToggleDropdown,
    handleDropdownAction,
    togglePushNotifications,
    handleChangeNotificationFilter,
  } = customHook;

  return (
    <>
      <S.TopContainer>
        <S.GoBackButton onClick={handleGoBack}>
          <svg viewBox="0 0 32 32">
            <path d="M29.333 16c0 0.736-0.596 1.333-1.333 1.333h-20.781l8.391 8.391c0.521 0.521 0.521 1.364 0 1.885-0.26 0.26-0.601 0.391-0.943 0.391s-0.683-0.131-0.943-0.391l-10.667-10.667c-0.123-0.123-0.22-0.271-0.288-0.433-0.135-0.327-0.135-0.693 0-1.019 0.068-0.163 0.165-0.311 0.288-0.433l10.667-10.667c0.521-0.521 1.364-0.521 1.885 0s0.521 1.364 0 1.885l-8.391 8.391h20.781c0.737 0 1.333 0.597 1.333 1.333z"></path>
          </svg>
        </S.GoBackButton>

        <S.MainTitle>{t('notifications.title')}</S.MainTitle>
      </S.TopContainer>

      <S.Row>
        <S.FiltersContainer>
          <S.FilterItem
            active={notificationFilter === 'ALL'}
            onClick={() =>
              handleChangeNotificationFilter(NotificationFilter.ALL)
            }
          >
            <S.FilterItemText active={notificationFilter === 'ALL'}>
              {t('notifications.filters.all')}
            </S.FilterItemText>
          </S.FilterItem>

          <S.FilterItem
            active={notificationFilter === 'READ'}
            onClick={() =>
              handleChangeNotificationFilter(NotificationFilter.READ)
            }
          >
            <S.FilterItemText active={notificationFilter === 'READ'}>
              {t('notifications.filters.read')}
            </S.FilterItemText>
          </S.FilterItem>

          <S.FilterItem
            active={notificationFilter === 'UNREAD'}
            onClick={() =>
              handleChangeNotificationFilter(NotificationFilter.UNREAD)
            }
          >
            <S.FilterItemText active={notificationFilter === 'UNREAD'}>
              {t('notifications.filters.unread')}
            </S.FilterItemText>
          </S.FilterItem>
        </S.FiltersContainer>

        <S.MoreOptionsContainer>
          <S.MoreOptionsButton
            onClick={e => {
              e.stopPropagation();
              handleToggleDropdown();
            }}
          >
            <svg viewBox="0 0 32 32">
              <path d="M16 19.2c-1.765 0-3.2-1.435-3.2-3.2s1.435-3.2 3.2-3.2c1.765 0 3.2 1.435 3.2 3.2s-1.435 3.2-3.2 3.2zM30.4 16c0-1.765-1.435-3.2-3.2-3.2s-3.2 1.435-3.2 3.2c0 1.765 1.435 3.2 3.2 3.2s3.2-1.435 3.2-3.2zM8 16c0-1.765-1.435-3.2-3.2-3.2s-3.2 1.435-3.2 3.2c0 1.765 1.435 3.2 3.2 3.2s3.2-1.435 3.2-3.2z" />
            </svg>
          </S.MoreOptionsButton>

          <ClickOutHandler onClickOut={handleCloseDropdown}>
            <S.DropdownContainer active={toggleDropdown}>
              {Object.values(NotificationDropdownOptions).map(item => {
                if (notifications.length === 0) {
                  if (item === NotificationDropdownOptions.MARK_ALL)
                    return null;
                  if (item === NotificationDropdownOptions.REMOVE_ALL)
                    return null;
                }

                return (
                  <S.DropdownItemContainer
                    key={item}
                    onClick={e => {
                      e.stopPropagation();
                      handleDropdownAction(item);
                    }}
                  >
                    {notificationDropdownIcons[item]}

                    <S.DropdownItemText>
                      {t(`notifications.dropdown.${item}`)}
                    </S.DropdownItemText>

                    {item === NotificationDropdownOptions.SETTINGS && (
                      <ToggleButton
                        width="unset"
                        checked={togglePushNotifications}
                        setChecked={() => {}}
                      />
                    )}
                  </S.DropdownItemContainer>
                );
              })}
            </S.DropdownContainer>
          </ClickOutHandler>
        </S.MoreOptionsContainer>
      </S.Row>
    </>
  );
};

export default NotificationsHeaderSection;
