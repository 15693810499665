import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 2rem;
  margin-bottom: auto;
  border-radius: 2rem;
  border: solid 1px #eee;
  flex-direction: column;
  background-color: white;
`;

export const ItemContainer = styled.div`
  display: flex;
  height: 8.2rem;
  align-items: center;
  border-radius: 2rem;
  cursor: pointer;

  &:hover {
    opacity: 0.8;
  }
`;

export const ItemNotificationAvatarContainer = styled.div`
  gap: 2rem;
  display: flex;
  margin-right: 3rem;
  align-items: center;
`;

export const ItemNotificationDot = styled.div<{ active: boolean }>`
  width: 0.6rem;
  height: 0.6rem;
  border-radius: 50%;
  background-color: ${({ active }) => (active ? '#feb038' : 'transparent')};
`;

export const ItemAvatar = styled.div`
  width: 5rem;
  height: 5rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background-color: #eff1f6;

  img {
    width: 5rem;
    height: 5rem;
    border-radius: 50%;
  }

  svg {
    width: 3rem;
    height: 3rem;
    fill: #8492a6;
  }
`;

export const ItemDescriptionContainer = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
`;

export const ItemDescriptionTitle = styled.h6`
  color: #292929;
  font-weight: 600;
  font-size: 1.4rem;
`;

export const ItemDescriptionSubtitle = styled.p`
  color: #676767;
  font-weight: 400;
  font-size: 1.2rem;
  line-height: 1.8rem;
`;

export const ItemDescriptionDate = styled.span`
  color: #8492a6;
  font-weight: 500;
  font-size: 1.1rem;
`;

export const ActionsContainer = styled.div`
  gap: 2rem;
  display: flex;
  margin-left: auto;
  align-items: center;
`;

export const RemoveButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;

  &:hover {
    background-color: #eff1f6;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: #8492a6;
  }
`;

export const NavigateButton = styled(RemoveButton)``;

export const ItemDivider = styled.div`
  height: 0.1rem;
  margin: 3rem 0;
  background-color: #eee;
`;
