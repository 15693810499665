import styled from 'styled-components';

export const Card = styled.div`
  display: flex;
  padding: 4rem;
  margin-top: 3rem;
  border-radius: 2rem;
  border: solid 1px #eee;
  flex-direction: column;
  background-color: white;
`;

export const TopCardContainer = styled.div`
  gap: 3rem;
  display: flex;
  margin-bottom: 3rem;

  @media (max-width: 795px) {
    flex-direction: column;
  }
`;

export const BottomCardContainer = styled.div`
  gap: 3rem;
  display: flex;

  @media (max-width: 1190px) {
    flex-direction: column;
  }
`;

export const Row = styled.div`
  gap: 3rem;
  display: flex;

  &:first-child {
    flex: 1;

    @media (max-width: 638px) {
      flex-direction: column;
    }
  }

  &:last-child {
    @media (max-width: 758px) {
      flex-direction: column;
    }
  }
`;

export const InputContainer = styled.div<{ rounded?: boolean }>`
  flex: 1;
  display: flex;
  overflow: hidden;
  padding: 0 1.2rem;
  align-items: center;
  transition: all 0.3s ease;
  box-shadow: 0 0 0 0.2rem #f4f4f4;
  border-radius: ${({ rounded }) => (rounded ? '20rem' : '0.6rem')};
  background-color: ${({ rounded }) => (rounded ? '#F4F4F4' : '#ffffff')};

  &:hover,
  &:focus-within {
    box-shadow: 0 0 0 0.2rem #292929;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: #bfbfbf;
    margin-right: 1rem;
  }

  input {
    width: 100%;
    height: 4rem;
    border: none;
    outline: none;
    color: #bfbfbf;
    line-height: 2rem;
    font-weight: 400;
    font-size: 1.6rem;
    transition: all 0.3s ease;
    background: ${({ rounded }) => (rounded ? '#F4F4F4' : '#ffffff')};

    &::placeholder {
      color: #bfbfbf;
    }

    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:focus,
    &:-webkit-autofill:active {
      -webkit-text-fill-color: #bfbfbf;
      -webkit-box-shadow: 0 0 0 40rem
        ${({ rounded }) => (rounded ? '#F4F4F4' : '#ffffff')} inset;
    }
  }
`;

export const AutoUpdateContainer = styled.div`
  gap: 1rem;
  display: flex;
  align-self: center;
  align-items: center;
  flex-direction: row;
`;

export const AutoUpdateLabel = styled.span`
  color: #676767;
  font-weight: 400;
  line-height: 1.8;
  font-size: 1.4rem;
`;
