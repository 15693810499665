import {
  useNotifications,
  NotificationsList,
  NotificationsHeaderSection,
} from 'features/Notifications';

import * as S from './Styles';

const Notifications = () => {
  const notificationsHook = useNotifications();

  return (
    <S.MainContainer>
      <NotificationsHeaderSection customHook={notificationsHook} />
      <NotificationsList customHook={notificationsHook} />
    </S.MainContainer>
  );
};

export default Notifications;
