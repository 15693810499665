import styled, { keyframes } from 'styled-components';

export const MainContainer = styled.div`
  flex-grow: 1;
  display: flex;
  padding: 6.9rem;
  flex-direction: column;
`;

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const RowContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  margin-right: 2rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: #292929;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
  font-size: 2rem;
  font-weight: 600;
`;

export const ActionsContainer = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
`;

const shakeAnimation = keyframes`
  0% { transform: rotate(0); }
  25% { transform: rotate(-10deg); }
  50% { transform: rotate(10deg); }
  75% { transform: rotate(-10deg); }
  100% { transform: rotate(0); }
`;

export const StyledButton = styled.div`
  gap: 1rem;
  height: 4rem;
  display: flex;
  padding: 0 1.4rem;
  align-items: center;
  border-radius: 0.6rem;
  justify-content: center;
  transition: all 0.3s ease-in-out;
  cursor: pointer;

  &:hover {
    background: #eff1f6;

    .settingsIcon {
      transform: rotate(360deg);
      transition: transform 1s ease-in-out;
    }

    .deleteIcon {
      animation: ${shakeAnimation} 0.4s ease-in-out;
    }
  }

  &:active {
    background: #eff1f6;
  }

  svg {
    width: 1.8rem;
    height: 1.8rem;
    fill: #676767;
  }
`;

export const ButtonLabel = styled.span`
  color: #676767;
  font-weight: 500;
  font-size: 1.2rem;
  line-height: 1.4rem;
`;

export const Timeframe = styled.div`
  display: flex;
  margin-top: 3rem;
  justify-content: flex-end;

  p {
    color: #676767;
    background: #fff;
    font-size: 1.4rem;
    min-height: 3.2rem;
    border-radius: 1.9rem;
    padding: 0.4rem 1.4rem;
    border: 0.1rem solid #eee;
  }
`;
