import {
  Notification,
  NotificationSettings,
  RawNotificationSettings,
  NotificationSettingsType,
  NotificationSettingsScope,
} from 'features/Notifications/types';
import config from 'Services/config';
import { AxiosInstance } from 'axios';

const createNotificationsInstanceApi = (instance: AxiosInstance) => {
  const getNotifications = async (): Promise<Notification[]> => {
    const url = config.ENDPOINTS.NOTIFICATIONS.GET_NOTIFICATIONS;

    const { data: result } = await instance.get(url);

    return result;
  };

  const markAsReadById = async (data: Notification): Promise<boolean> => {
    const url = `${config.ENDPOINTS.NOTIFICATIONS.MARK_AS_READ_BY_ID}/${data.id}`;

    const { data: result } = await instance.put(url);

    return result;
  };

  const deleteById = async (data: Notification): Promise<boolean> => {
    const url = `${config.ENDPOINTS.NOTIFICATIONS.DELETE_BY_ID}/${data.id}`;

    const { data: result } = await instance.delete(url);

    return result;
  };

  const dismissAll = async (): Promise<boolean> => {
    const url = config.ENDPOINTS.NOTIFICATIONS.DISMISS_ALL;

    const { data: result } = await instance.put(url);

    return result;
  };

  const markAllAsReadNotifications = async (): Promise<boolean> => {
    const url = config.ENDPOINTS.NOTIFICATIONS.MARK_ALL_AS_READ_NOTIFICATIONS;

    const { data: result } = await instance.put(url);

    return result;
  };

  const updateNotificationSettings = async (
    input: NotificationSettings,
  ): Promise<boolean> => {
    const data = {
      type: NotificationSettingsType.PUSH_NOTIFICATION,
      scope: NotificationSettingsScope.NOTIFICATION,
      active: input.pushNotification,
    };

    const url = config.ENDPOINTS.NOTIFICATIONS.UPDATE_SETTINGS;

    const { data: result } = await instance.patch(url, data);

    return result;
  };

  const getNotificationSettings = async (): Promise<
    RawNotificationSettings[]
  > => {
    const url = config.ENDPOINTS.NOTIFICATIONS.GET_SETTINGS;

    const { data: result } = await instance.get(url);

    return result;
  };

  return {
    getNotifications,
    markAsReadById,
    deleteById,
    dismissAll,
    markAllAsReadNotifications,
    updateNotificationSettings,
    getNotificationSettings,
  };
};

export default {
  createNotificationsInstanceApi,
};
