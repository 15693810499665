import {
  gendersAvailableValues,
  agentRoleIdsAvailableValues,
} from 'features/Discover/data/constants';
import { t } from 'i18next';
import moment from 'moment';
import Loader from 'react-loader-advanced';
import { Calendar } from 'react-date-range';
import { FormProvider } from 'react-hook-form';
import { useAdminAgent } from 'features/Admin';
import Button from 'components/shared/Button/Button';
import ComboBox from 'components/shared/ComboBox/ComboBox';
import { DEFAULT_USER_AVATAR } from 'Services/Utils/types';
import { IFile } from 'components/shared/FilePickerV2/types';
import FilePickerV2 from 'components/shared/FilePickerV2/FilePickerV2';

import * as S from './Styles';

const ClickOutHandler = require('react-onclickout');

const AdminAgent = () => {
  const {
    date,
    watch,
    errors,
    setDate,
    agentId,
    register,
    setValue,
    setFiles,
    handleGoBack,
    pageTypeLabel,
    adminAgentForm,
    handleSubmitForm,
    isLoadingAgentInfo,
    showCalendarPicker,
    setShowCalendarPicker,
    countriesAvailableValues,
    topicsOfInterestAvailableValues,
  } = useAdminAgent();

  const gender = watch('gender');
  const country = watch('country');
  const roleIds = watch('roleIds');
  const picture = watch('displayPicture');
  const dateOfBirth = watch('dateOfBirth');
  const topicsOfInterest = watch('topicsOfInterest');

  return (
    <FormProvider {...adminAgentForm}>
      <S.Container>
        <S.HeaderSection>
          <S.BackButton onClick={handleGoBack}>
            <svg viewBox="0 0 20 20">
              <path
                d="M17.9999 10.0003C17.9999 10.5522 17.5531 11.0003 16.9999 11.0003H5.4137L10.7068 16.2934C11.0981 16.6847 11.0981 17.3166 10.7068 17.7072C10.5118 17.9022 10.2556 18.0003 9.99995 18.0003C9.74432 18.0003 9.48807 17.9022 9.29307 17.7072L2.29307 10.7072C1.90182 10.3159 1.90182 9.68406 2.29307 9.29344L9.29307 2.29344C9.68432 1.90219 10.3162 1.90219 10.7068 2.29344C11.0974 2.68469 11.0981 3.31656 10.7068 3.70719L5.4137 9.00031H16.9999C17.5531 9.00031 17.9999 9.44844 17.9999 10.0003Z"
                fill="#292929"
              />
            </svg>
          </S.BackButton>

          <S.MainTitle>{pageTypeLabel}</S.MainTitle>
        </S.HeaderSection>

        <S.Card>
          <Loader
            show={isLoadingAgentInfo}
            message={t('generic.loading')}
            style={{ margin: '-3rem', padding: '3rem' }}
          >
            <S.FormSectionBlock>
              <S.Row>
                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.name')}
                  </S.FormGroupLabel>

                  <S.Input
                    type="text"
                    {...register('name')}
                    placeholder={t('skorrAdmin.agent.name')}
                  />

                  {errors.name && (
                    <S.ErrorText>*{errors.name.message}</S.ErrorText>
                  )}
                </S.FormGroup>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.email')}
                  </S.FormGroupLabel>

                  <S.Input
                    type="text"
                    disabled={!!agentId}
                    {...register('email')}
                    placeholder={t('skorrAdmin.agent.email')}
                  />

                  {errors.email && (
                    <S.ErrorText>*{errors.email.message}</S.ErrorText>
                  )}
                </S.FormGroup>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t(
                      `skorrAdmin.agent.${
                        agentId ? 'newPassword' : 'password'
                      }`,
                    )}
                  </S.FormGroupLabel>

                  <S.Input
                    type="text"
                    {...register(agentId ? 'newPassword' : 'password')}
                    placeholder={t(
                      `skorrAdmin.agent.${
                        agentId ? 'newPassword' : 'password'
                      }`,
                    )}
                  />

                  {agentId
                    ? errors.newPassword && (
                        <S.ErrorText>*{errors.newPassword.message}</S.ErrorText>
                      )
                    : errors.password && (
                        <S.ErrorText>*{errors.password.message}</S.ErrorText>
                      )}
                </S.FormGroup>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.choosePicture')}
                  </S.FormGroupLabel>

                  <S.FilePickerContainer>
                    <FilePickerV2
                      single={true}
                      type={'images'}
                      initialImages={[picture!]}
                      showSelectedFiles={false}
                      buttonBorderRadius={'5rem'}
                      text={t('generic.selectPicture')}
                      handleChange={(files: IFile[]) => {
                        if (files.length) {
                          setFiles(files);
                        } else {
                          setFiles([]);
                          setValue('displayPicture', DEFAULT_USER_AVATAR);
                        }
                      }}
                    />
                  </S.FilePickerContainer>
                </S.FormGroup>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.topicsOfInterest')}
                  </S.FormGroupLabel>

                  <ComboBox
                    type="multi"
                    width="25rem"
                    height="18rem"
                    borderRadius="0.6rem"
                    value={topicsOfInterest}
                    valueKey="topicsOfInterest"
                    placeholder={t('skorrAdmin.agent.selectTopcisOfInterest')}
                    isClearable={true}
                    isFilterable={true}
                    valuesAvailable={topicsOfInterestAvailableValues}
                    onChange={(obj: any) => {
                      if (obj.topicsOfInterest) {
                        setValue('topicsOfInterest', obj.topicsOfInterest);
                      }
                    }}
                    optionContainerBorderRadius={'0.6rem'}
                    innerOptionsContainerPaddingTop={'1.2rem'}
                    innerOptionsContainerPaddingBottom={'1.2rem'}
                    innerOptionsContainerPaddingLeft={'1.2rem'}
                    innerOptionsContainerPaddingRight={'1.2rem'}
                    optionContainerPaddingTop={'1rem'}
                    optionContainerPaddingBottom={'1rem'}
                    optionContainerPaddingLeft={'1rem'}
                    optionContainerPaddingRight={'1rem'}
                    changeBackgroundColorOnHover={false}
                  />

                  {errors.topicsOfInterest && (
                    <S.ErrorText>
                      *{errors.topicsOfInterest.message}
                    </S.ErrorText>
                  )}
                </S.FormGroup>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.country')}
                  </S.FormGroupLabel>

                  <ComboBox
                    type="single"
                    width="25rem"
                    height="4.2rem"
                    value={country}
                    valueKey="country"
                    placeholder={t('skorrAdmin.agent.selectCountry')}
                    isClearable={true}
                    isFilterable={true}
                    valuesAvailable={countriesAvailableValues}
                    onChange={(obj: any) => {
                      if (obj.country?.value) {
                        setValue('country', obj.country);
                      } else if (obj.country === undefined) {
                        setValue('country', undefined);
                      }
                    }}
                    optionContainerBorderRadius={'0.6rem'}
                    innerOptionsContainerPaddingTop={'1.2rem'}
                    innerOptionsContainerPaddingBottom={'1.2rem'}
                    innerOptionsContainerPaddingLeft={'1.2rem'}
                    innerOptionsContainerPaddingRight={'1.2rem'}
                    optionContainerPaddingTop={'1rem'}
                    optionContainerPaddingBottom={'1rem'}
                    optionContainerPaddingLeft={'1rem'}
                    optionContainerPaddingRight={'1rem'}
                    changeBackgroundColorOnHover={false}
                  />

                  {errors.country && (
                    <S.ErrorText>*{errors.country.message}</S.ErrorText>
                  )}
                </S.FormGroup>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.gender')}
                  </S.FormGroupLabel>

                  <ComboBox
                    type="single"
                    width="25rem"
                    height="4.2rem"
                    value={gender ?? undefined}
                    valueKey="gender"
                    placeholder={t('skorrAdmin.agent.selectGender')}
                    isClearable={true}
                    isFilterable={false}
                    valuesAvailable={gendersAvailableValues}
                    onChange={(obj: any) => {
                      if (obj.gender) {
                        setValue('gender', obj.gender);
                      } else {
                        setValue('gender', null);
                      }
                    }}
                    optionContainerBorderRadius={'0.6rem'}
                    innerOptionsContainerPaddingTop={'1.2rem'}
                    innerOptionsContainerPaddingBottom={'1.2rem'}
                    innerOptionsContainerPaddingLeft={'1.2rem'}
                    innerOptionsContainerPaddingRight={'1.2rem'}
                    optionContainerPaddingTop={'1rem'}
                    optionContainerPaddingBottom={'1rem'}
                    optionContainerPaddingLeft={'1rem'}
                    optionContainerPaddingRight={'1rem'}
                    changeBackgroundColorOnHover={false}
                  />

                  {errors.gender && (
                    <S.ErrorText>*{errors.gender.message}</S.ErrorText>
                  )}
                </S.FormGroup>

                <ClickOutHandler
                  onClickOut={() => {
                    setShowCalendarPicker(false);

                    const activeInputContainer = document.querySelector(
                      '.rdrDateRangeWrapper .rdrDateDisplayItemActive',
                    );

                    activeInputContainer?.classList.remove(
                      'rdrDateDisplayItemActive',
                    );
                  }}
                >
                  <S.FormGroup>
                    <S.FormGroupLabel>
                      {t('skorrAdmin.agent.dateOfBirth')}
                    </S.FormGroupLabel>

                    <S.InputContainer>
                      <S.Input
                        type="text"
                        value={
                          dateOfBirth
                            ? moment(dateOfBirth).format('L')
                            : undefined
                        }
                        {...register('dateOfBirth')}
                        placeholder={t('skorrAdmin.agent.dateOfBirth')}
                      />

                      <S.CalendarButton
                        onClick={() =>
                          setShowCalendarPicker(!showCalendarPicker)
                        }
                      >
                        <svg viewBox="0 0 32 32">
                          <path
                            d="M4.333 26.833c0 1.38 1.12 2.5 2.5 2.5h18.333c1.38 0 2.5-1.12 2.5-2.5v-14.167h-23.333v14.167zM21 16.625c0-0.344 0.281-0.625 0.625-0.625h2.083c0.344 0 0.625 0.281 0.625 0.625v2.083c0 0.344-0.281 0.625-0.625 0.625h-2.083c-0.344 0-0.625-0.281-0.625-0.625v-2.083zM21 23.292c0-0.344 0.281-0.625 0.625-0.625h2.083c0.344 0 0.625 0.281 0.625 0.625v2.083c0 0.344-0.281 0.625-0.625 0.625h-2.083c-0.344 0-0.625-0.281-0.625-0.625v-2.083zM14.333 16.625c0-0.344 0.281-0.625 0.625-0.625h2.083c0.344 0 0.625 0.281 0.625 0.625v2.083c0 0.344-0.281 0.625-0.625 0.625h-2.083c-0.344 0-0.625-0.281-0.625-0.625v-2.083zM14.333 23.292c0-0.344 0.281-0.625 0.625-0.625h2.083c0.344 0 0.625 0.281 0.625 0.625v2.083c0 0.344-0.281 0.625-0.625 0.625h-2.083c-0.344 0-0.625-0.281-0.625-0.625v-2.083zM7.667 16.625c0-0.344 0.281-0.625 0.625-0.625h2.083c0.344 0 0.625 0.281 0.625 0.625v2.083c0 0.344-0.281 0.625-0.625 0.625h-2.083c-0.344 0-0.625-0.281-0.625-0.625v-2.083zM7.667 23.292c0-0.344 0.281-0.625 0.625-0.625h2.083c0.344 0 0.625 0.281 0.625 0.625v2.083c0 0.344-0.281 0.625-0.625 0.625h-2.083c-0.344 0-0.625-0.281-0.625-0.625v-2.083zM25.167 6h-2.5v-2.5c0-0.458-0.375-0.833-0.833-0.833h-1.667c-0.458 0-0.833 0.375-0.833 0.833v2.5h-6.667v-2.5c0-0.458-0.375-0.833-0.833-0.833h-1.667c-0.458 0-0.833 0.375-0.833 0.833v2.5h-2.5c-1.38 0-2.5 1.12-2.5 2.5v2.5h23.333v-2.5c0-1.38-1.12-2.5-2.5-2.5z"
                            fill="#cccccc"
                          />
                        </svg>
                      </S.CalendarButton>
                    </S.InputContainer>

                    <S.CalendarPickerContainer visible={showCalendarPicker}>
                      <Calendar
                        date={date}
                        maxDate={moment().toDate()}
                        onChange={(item: any) => {
                          setValue('dateOfBirth', moment(item).utc().valueOf());
                          setDate(item);
                          setShowCalendarPicker(false);
                        }}
                      />
                    </S.CalendarPickerContainer>

                    {errors.dateOfBirth && (
                      <S.ErrorText>*{errors.dateOfBirth.message}</S.ErrorText>
                    )}
                  </S.FormGroup>
                </ClickOutHandler>

                <S.FormGroup>
                  <S.FormGroupLabel>
                    {t('skorrAdmin.agent.roles')}
                  </S.FormGroupLabel>

                  <ComboBox
                    type="single"
                    width="25rem"
                    height="4.2rem"
                    value={roleIds}
                    valueKey="roleIds"
                    placeholder={t('skorrAdmin.agent.selectRole')}
                    isClearable={true}
                    isFilterable={false}
                    valuesAvailable={agentRoleIdsAvailableValues}
                    onChange={(obj: any) => {
                      if (obj.roleIds !== roleIds?.[0]) {
                        setValue('roleIds', [obj.roleIds || []].flat());
                      }
                    }}
                    optionContainerBorderRadius={'0.6rem'}
                    innerOptionsContainerPaddingTop={'1.2rem'}
                    innerOptionsContainerPaddingBottom={'1.2rem'}
                    innerOptionsContainerPaddingLeft={'1.2rem'}
                    innerOptionsContainerPaddingRight={'1.2rem'}
                    optionContainerPaddingTop={'1rem'}
                    optionContainerPaddingBottom={'1rem'}
                    optionContainerPaddingLeft={'1rem'}
                    optionContainerPaddingRight={'1rem'}
                    changeBackgroundColorOnHover={false}
                  />

                  {errors.roleIds && (
                    <S.ErrorText>*{errors.roleIds.message}</S.ErrorText>
                  )}
                </S.FormGroup>
              </S.Row>
            </S.FormSectionBlock>

            <S.FooterContainer>
              <Button
                marginLeft="auto"
                borderRadius="3rem"
                onClick={handleSubmitForm}
              >
                {pageTypeLabel}
              </Button>
            </S.FooterContainer>
          </Loader>
        </S.Card>
      </S.Container>
    </FormProvider>
  );
};

export default AdminAgent;
