import styled from 'styled-components';

export const TopContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const GoBackButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  min-width: 4rem;
  margin-right: 2rem;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 2rem;
    height: 2rem;
    fill: #292929;
    vertical-align: middle;
  }

  &:active {
    background: #eff1f6;
  }

  &:hover {
    background: #eff1f6;
  }
`;

export const MainTitle = styled.h1`
  color: #292929;
  font-size: 2rem;
  font-weight: 600;
`;

export const Row = styled.div`
  display: flex;
  margin-top: 5rem;
  align-items: center;
  margin-bottom: 3rem;
  justify-content: space-between;
`;

export const FiltersContainer = styled.div`
  gap: 1rem;
  display: flex;
  align-items: center;
`;

export const FilterItem = styled.div<{ active?: boolean }>`
  height: 4rem;
  display: flex;
  padding: 0 1.6rem;
  align-items: center;
  border-radius: 2rem;
  background-color: ${({ active }) => (active ? '#292929' : '#ffffff')};
  border: ${({ active }) => (active ? 'unset' : '0.1rem solid #eeeeee')};
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }
`;

export const FilterItemText = styled.span<{ active?: boolean }>`
  font-size: 1.4rem;
  font-weight: ${({ active }) => (active ? '500' : '400')};
  color: ${({ active }) => (active ? '#ffffff' : '#676767')};
`;

export const MoreOptionsContainer = styled.div`
  display: flex;
  position: relative;
  align-items: center;
`;

export const MoreOptionsButton = styled.div`
  width: 4rem;
  height: 4rem;
  display: flex;
  align-items: center;
  border-radius: 4rem;
  justify-content: center;
  background-color: white;
  border: 0.1rem solid #eeeeee;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 1rem rgba(0, 0, 0, 0.1);
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: #676767;
  }
`;

export const DropdownContainer = styled.div<{ active: boolean }>`
  right: 0;
  top: 100%;
  z-index: 10;
  padding: 1rem;
  margin-top: 1rem;
  background: #fff;
  position: absolute;
  transition: all 0.08s;
  border-radius: 0.6rem;
  flex-direction: column;
  display: ${({ active }) => (active ? 'flex' : 'none')};
  box-shadow: 0 0.6rem 1rem rgba(0, 0, 0, 0.14),
    0 0.1rem 1.8rem rgba(0, 0, 0, 0.12), 0 0.3rem 0.5rem rgba(0, 0, 0, 0.2);
`;

export const DropdownItemContainer = styled.div`
  gap: 1rem;
  width: 100%;
  display: flex;
  padding: 1rem 2rem;
  margin-top: 0.4rem;
  align-items: center;
  border-radius: 0.6rem;
  cursor: pointer;

  &:first-of-type {
    margin-top: 0;
  }

  &:hover {
    background: #f7f8fa;
  }

  &:active {
    background: #eff1f6;
  }

  svg {
    width: 2rem;
    height: 2rem;
    fill: #676767;
  }
`;

export const DropdownItemText = styled.div`
  color: #292929;
  font-weight: 400;
  overflow: hidden;
  font-size: 1.4rem;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
