import { t } from 'i18next';
import Button from 'components/shared/Button/Button';
import ToggleButton from 'components/shared/ToggleButton/ToggleButton';
import { socialNetworkAvailableValues } from 'features/Benchmark/data/constants';
import { SubmitBenchmarkHookProps } from 'features/Benchmark/hooks/use-submit-benchmark';

import * as S from './Styles';
import SubmitBenchmarkFormDropdown from '../SubmitBenchmarkFormDropdown';
import SubmitBenchmarkFormCalendar from '../SubmitBenchmarkFormCalendar';

type Props = {
  customHook: SubmitBenchmarkHookProps;
};

const SubmitBenchmarkForm = ({ customHook }: Props) => {
  const {
    username,
    socialNetwork,
    selectedUsers,
    benchmarkName,
    listSelectedId,
    listsAvailable,
    toggleListButton,
    toggleAutoUpdate,
    primaryButtonLabel,
    toggleNetworkButton,
    handleChangeUsername,
    handleSubmitBenchmark,
    handleToggleListButton,
    handleToggleAutoUpdate,
    handleChangeSocialNetwork,
    handleChangeBenchmarkName,
    handleToggleNetworkButton,
    handleChangeListSelectedId,
    handleClickOutToggleListButton,
    handleClickOutToggleNetworkButton,
  } = customHook;

  return (
    <S.Card>
      <S.TopCardContainer>
        <S.InputContainer>
          <input
            type="text"
            name="benchmarkName"
            value={benchmarkName}
            placeholder={t('benchmark.form.namePlaceholder')}
            onChange={e => handleChangeBenchmarkName(e.target.value)}
          />
        </S.InputContainer>

        <Button
          fontWeight="700"
          fontSize="1.4rem"
          textAlign="center"
          paddingLeft="6rem"
          paddingRight="6rem"
          borderRadius="0.6rem"
          onClick={handleSubmitBenchmark}
          disabled={selectedUsers.length < 2}
        >
          {primaryButtonLabel}
        </Button>
      </S.TopCardContainer>

      <S.BottomCardContainer>
        <S.Row>
          <S.InputContainer rounded>
            <svg viewBox="0 0 20 20">
              <path d="M18.2369 17.2128L14.8131 13.789C15.878 12.4962 16.5178 10.84 16.5178 9.03428C16.5178 4.90128 13.1674 1.55078 9.03434 1.55078C4.90128 1.55078 1.5509 4.90128 1.5509 9.03434C1.5509 13.1674 4.90134 16.5178 9.0344 16.5178C10.8401 16.5178 12.4963 15.878 13.7891 14.8131L17.2129 18.2369C17.4957 18.5197 17.9542 18.5197 18.237 18.2369C18.5198 17.9541 18.5198 17.4955 18.2369 17.2128ZM2.99928 9.03434C2.99928 5.70128 5.70128 2.99928 9.03434 2.99928C12.3674 2.99928 15.0694 5.70128 15.0694 9.03434C15.0694 12.3674 12.3674 15.0694 9.03434 15.0694C5.70128 15.0694 2.99928 12.3674 2.99928 9.03434Z" />
            </svg>

            <input
              type="text"
              name="handler"
              value={username}
              placeholder={t('benchmark.form.handlerPlaceholder')}
              onChange={e => handleChangeUsername(e.target.value)}
            />
          </S.InputContainer>

          <SubmitBenchmarkFormDropdown
            resource={'socialNetworks'}
            disabled={selectedUsers.length > 0}
            toggleButton={toggleNetworkButton}
            valueSelected={socialNetwork}
            valuesAvailable={socialNetworkAvailableValues}
            handleToggleButton={handleToggleNetworkButton}
            handleChangeValueSelected={handleChangeSocialNetwork}
            handleClickOutToggleButton={handleClickOutToggleNetworkButton}
          />
        </S.Row>

        <S.Row>
          <SubmitBenchmarkFormDropdown
            isClearable
            isFilterable
            resource={'benchmark.form.listPlaceholder'}
            toggleButton={toggleListButton}
            valueSelected={listSelectedId!}
            valuesAvailable={listsAvailable}
            handleToggleButton={handleToggleListButton}
            handleChangeValueSelected={handleChangeListSelectedId}
            handleClickOutToggleButton={handleClickOutToggleListButton}
          />

          <SubmitBenchmarkFormCalendar customHook={customHook} />

          <S.AutoUpdateContainer>
            <S.AutoUpdateLabel>
              {t('benchmark.form.autoUpdate')}
            </S.AutoUpdateLabel>
            <ToggleButton
              width="unset"
              checked={toggleAutoUpdate}
              setChecked={handleToggleAutoUpdate}
            />
          </S.AutoUpdateContainer>
        </S.Row>
      </S.BottomCardContainer>
    </S.Card>
  );
};

export default SubmitBenchmarkForm;
