/* eslint-disable no-nested-ternary */
import { t } from 'i18next';
import { useTheme } from 'styled-components';
import { useFormContext } from 'react-hook-form';
import ComboBox from 'components/shared/ComboBox/ComboBox';

import * as S from './Styles';

type Props = {
  title: string;
  value: any;
  valueKey: string;
  optionValues: any;
  placeholder: string;
  type?: 'single' | 'multi';
  isFilterable?: boolean;
};

const DiscoverToolBarFilterSideBarDropdown = ({
  title,
  value,
  valueKey,
  optionValues,
  placeholder,
  type = 'single',
  isFilterable = false,
}: Props) => {
  const themeGlobal = useTheme();

  const {
    trigger,
    setValue,
    formState: { errors },
  } = useFormContext();

  return (
    <S.ToolBarFilterSideBarInputContainer>
      <S.ToolBarFilterSideBarInputTitle>
        {t(`discover.${title}`)}
      </S.ToolBarFilterSideBarInputTitle>

      <ComboBox
        width={'100%'}
        type={type}
        height={'4.4rem'}
        growWithoutScroll
        value={value}
        valueKey={valueKey}
        borderRadius={'0.6rem'}
        placeholder={placeholder}
        valuesAvailable={optionValues}
        isClearable={true}
        isFilterable={isFilterable}
        onChange={(obj: any) => {
          setValue(
            `filters.${valueKey}`,
            valueKey === 'countryCode'
              ? [obj?.[valueKey]?.value || []].flat()
              : ['category', 'topicsInterest'].includes(valueKey)
              ? obj[valueKey]
              : [obj?.[valueKey] || []].flat(),
          );
          trigger(`filters.${valueKey}`);
        }}
        optionContainerBorderRadius={'0.6rem'}
        availableOptionsContainerBorderRadius={'0.6rem'}
        innerOptionsContainerPaddingTop={'1.2rem'}
        innerOptionsContainerPaddingBottom={'1.2rem'}
        innerOptionsContainerPaddingLeft={'1.2rem'}
        innerOptionsContainerPaddingRight={'1.2rem'}
        innerOptionsContainerTextAlign={'start'}
        optionContainerPaddingTop={'1rem'}
        optionContainerPaddingBottom={'1rem'}
        optionContainerPaddingLeft={'1rem'}
        optionContainerPaddingRight={'1rem'}
        innerOptionsContainerMaxHeight={'25rem'}
        optionSelectedContainerPaddingLeft={'0'}
        changeBackgroundColorOnHover={false}
        themeStyles={(themeGlobal as any).comboBoxTopicsOfInterest}
      />

      {errors.filters?.[valueKey as keyof unknown] && (
        <S.ToolBarFilterSideBarErrorText>
          * {errors.filters?.[valueKey as keyof unknown]?.['message' as any]}
        </S.ToolBarFilterSideBarErrorText>
      )}
    </S.ToolBarFilterSideBarInputContainer>
  );
};

export default DiscoverToolBarFilterSideBarDropdown;
