import moment from 'moment';
import { NotificationType } from 'features/Notifications/types';
import { notificationIcons } from 'features/Notifications/data/constants';
import { NotificationsHookProps } from 'features/Notifications/hooks/use-notifications';

import * as S from './Styles';
import { NotificationsEmptyState } from '../NotificationsEmptyState';

type Props = {
  customHook: NotificationsHookProps;
};

const NotificationsList = ({ customHook }: Props) => {
  const { notifications, handleDeleteById, handleMarkAsReadById } = customHook;

  return (
    <S.Card>
      {notifications.length === 0 ? (
        <NotificationsEmptyState />
      ) : (
        notifications.map((notification, index) => {
          const lastItem = notifications.length - 1 === index;

          const showNavigationIcon = ![
            NotificationType.GENERIC,
            NotificationType.NEW_RELEASE,
            NotificationType.REQUEST_USER_EMAIL,
            NotificationType.USER_REFERRED_SUCCESSFULLY,
            NotificationType.INFLUENCER_DATA_SHARE_REVOKE,
          ].includes(NotificationType[notification.type]);

          return (
            <div key={notification.id}>
              <S.ItemContainer
                onClick={() =>
                  handleMarkAsReadById(notification, notification.type)
                }
              >
                <S.ItemNotificationAvatarContainer>
                  <S.ItemNotificationDot active={!notification.readAt} />
                  <S.ItemAvatar>
                    {notification.metadata?.img ? (
                      <img src={notification.metadata.img} />
                    ) : (
                      notificationIcons[notification.type]
                    )}
                  </S.ItemAvatar>
                </S.ItemNotificationAvatarContainer>

                <S.ItemDescriptionContainer>
                  <S.ItemDescriptionTitle>
                    {notification.title}
                  </S.ItemDescriptionTitle>
                  <S.ItemDescriptionSubtitle>
                    {notification.message}
                  </S.ItemDescriptionSubtitle>
                  <S.ItemDescriptionDate>
                    {moment(notification.createdAt).fromNow()}
                  </S.ItemDescriptionDate>
                </S.ItemDescriptionContainer>

                <S.ActionsContainer>
                  <S.RemoveButton
                    onClick={e => {
                      e.stopPropagation();
                      handleDeleteById(notification);
                    }}
                  >
                    <svg viewBox="0 0 32 32">
                      <path d="M28 6.667h-5.333v-1.333c0-1.068-0.416-2.073-1.172-2.828-0.755-0.756-1.76-1.172-2.828-1.172h-5.333c-1.068 0-2.073 0.416-2.828 1.172-0.756 0.755-1.172 1.76-1.172 2.828v1.333h-5.333c-0.736 0-1.333 0.597-1.333 1.333s0.597 1.333 1.333 1.333h1.333v17.333c0 1.068 0.416 2.073 1.172 2.828 0.755 0.756 1.76 1.172 2.828 1.172h13.333c1.068 0 2.073-0.416 2.828-1.172s1.172-1.76 1.172-2.828v-17.333h1.333c0.737 0 1.333-0.597 1.333-1.333s-0.596-1.333-1.333-1.333zM12 5.333c0-0.351 0.143-0.695 0.391-0.943s0.592-0.391 0.943-0.391h5.333c0.351 0 0.695 0.143 0.943 0.391 0.249 0.248 0.391 0.592 0.391 0.943v1.333h-8v-1.333zM24 26.667c0 0.351-0.141 0.695-0.391 0.943s-0.592 0.391-0.943 0.391h-13.333c-0.351 0-0.695-0.141-0.943-0.391-0.248-0.248-0.391-0.592-0.391-0.943v-17.333h16v17.333zM14.667 14.667v8c0 0.737-0.597 1.333-1.333 1.333s-1.333-0.596-1.333-1.333v-8c0-0.736 0.597-1.333 1.333-1.333s1.333 0.597 1.333 1.333zM20 14.667v8c0 0.737-0.596 1.333-1.333 1.333s-1.333-0.596-1.333-1.333v-8c0-0.736 0.596-1.333 1.333-1.333s1.333 0.597 1.333 1.333z"></path>
                    </svg>
                  </S.RemoveButton>

                  {showNavigationIcon && (
                    <S.NavigateButton>
                      <svg viewBox="0 0 32 32">
                        <path d="M12.8 23.9c-0.384 0-0.768-0.147-1.060-0.44-0.585-0.585-0.585-1.535 0-2.121l5.339-5.339-5.34-5.34c-0.585-0.585-0.585-1.535 0-2.121s1.535-0.585 2.121 0l6.4 6.4c0.281 0.281 0.44 0.663 0.44 1.060s-0.159 0.779-0.44 1.060l-6.4 6.4c-0.292 0.295-0.676 0.441-1.060 0.441z"></path>
                      </svg>
                    </S.NavigateButton>
                  )}
                </S.ActionsContainer>
              </S.ItemContainer>

              {!lastItem && <S.ItemDivider />}
            </div>
          );
        })
      )}
    </S.Card>
  );
};

export default NotificationsList;
