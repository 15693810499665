import { t } from 'i18next';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { IErrorObject } from 'Services/Utils/types';
import { useCallback, useEffect, useState } from 'react';
import { logApiRequestError } from 'Services/Utils/Utils';

import {
  useDismissAll,
  useDeleteById,
  useMarkAsReadById,
  useGetNotifications,
  useGetNotificationSettings,
  useUpdateNotificationSettings,
  useMarkAllAsReadNotifications,
} from '../api';
import {
  Notification,
  NotificationType,
  NotificationFilter,
  NotificationDropdownOptions,
} from '../types';

export type NotificationsHookProps = {
  toggleDropdown: boolean;
  notifications: Notification[];
  isLoadingNotifications: boolean;
  togglePushNotifications: boolean;
  notificationFilter: NotificationFilter;
  handleMarkAsReadById: (
    notification: Notification,
    type: NotificationType,
  ) => Promise<void>;
  handleGoBack: () => void;
  handleCloseDropdown: () => void;
  handleToggleDropdown: () => void;
  handleDeleteById: (notification: Notification) => Promise<void>;
  handleDropdownAction: (action: NotificationDropdownOptions) => void;
  handleChangeNotificationFilter: (filter: NotificationFilter) => void;
};

export const useNotifications = () => {
  const navigate = useNavigate();

  const [toggleDropdown, setToggleDropdown] = useState(false);
  const [togglePushNotifications, setTogglePushNotifications] = useState(false);

  const [notificationFilter, setNotificationFilter] = useState(
    NotificationFilter.ALL,
  );

  const handleToggleDropdown = useCallback(() => {
    setToggleDropdown(prevState => !prevState);
  }, []);

  const handleCloseDropdown = useCallback(() => {
    setToggleDropdown(false);
  }, []);

  const handleTogglePushNotifications = useCallback(() => {
    setTogglePushNotifications(prevState => !prevState);
  }, []);

  const handleChangeNotificationFilter = useCallback(
    (filter: NotificationFilter) => {
      setNotificationFilter(filter);
    },
    [],
  );

  const { data, isLoading: isLoadingNotifications } = useGetNotifications();

  const notificationsFiltered = (data ?? []).filter(item => {
    if (notificationFilter === 'ALL') return true;
    if (notificationFilter === 'READ') return item.readAt !== null;
    if (notificationFilter === 'UNREAD') return item.readAt === null;
    return false;
  });

  const notifications = notificationsFiltered;

  const { mutateAsync: markAllAsReadNotifications, reset: resetMarkAllAsRead } =
    useMarkAllAsReadNotifications();

  const handleMarkAllAsReadNotifications = useCallback(async () => {
    try {
      await markAllAsReadNotifications();
    } catch (err) {
      toast.error(logApiRequestError(err as IErrorObject));
    }
    resetMarkAllAsRead();
  }, [markAllAsReadNotifications, resetMarkAllAsRead]);

  const handleGoToNotificationType = useCallback(
    async (type: NotificationType) => {
      switch (type) {
        case NotificationType.TOKEN_EXPIRED:
        case NotificationType.SOCIAL_ACCOUNT_PROCESSED:
          return navigate('/social-accounts-management');
        case NotificationType.MANAGER_DATA_SHARE_REVOKE:
        case NotificationType.ALLOW_DATA_SHARE:
          return navigate('/manager-data-access');
        default:
          return null;
      }
    },
    [navigate],
  );

  const { mutateAsync: markAsReadById, reset: resetMarkAsReadById } =
    useMarkAsReadById();

  const handleMarkAsReadById = useCallback(
    async (notification: Notification, type: NotificationType) => {
      try {
        await markAsReadById(notification);
      } catch (err) {
        toast.error(logApiRequestError(err as IErrorObject));
      }
      await handleGoToNotificationType(type);
      resetMarkAsReadById();
    },
    [markAsReadById, handleGoToNotificationType, resetMarkAsReadById],
  );

  const {
    reset: resetDismissAll,
    mutateAsync: dismissAll,
    isSuccess: isSuccessDismissAll,
  } = useDismissAll();

  const handleDismissAllNotifications = useCallback(() => {
    try {
      dismissAll();
    } catch (err) {
      toast.error(logApiRequestError(err as IErrorObject));
    }
    resetDismissAll();
  }, [dismissAll, resetDismissAll]);

  const {
    reset: resetDeleteById,
    mutateAsync: deleteById,
    isSuccess: isSuccessDeleteById,
  } = useDeleteById();

  const handleDeleteById = useCallback(
    async (notification: Notification) => {
      try {
        await deleteById(notification);
      } catch (err) {
        toast.error(logApiRequestError(err as IErrorObject));
      }
      resetDeleteById();
    },
    [deleteById, resetDeleteById],
  );

  const { data: notificationSettings } = useGetNotificationSettings();

  const { mutateAsync: updateNotificationSettings } =
    useUpdateNotificationSettings();

  const handleUpdateNotificationSettings = useCallback(
    async (pushNotification: boolean) => {
      try {
        await updateNotificationSettings({
          pushNotification,
        });
      } catch (err) {
        toast.error(logApiRequestError(err as IErrorObject));
      }
    },
    [updateNotificationSettings],
  );

  const handleGoBack = useCallback(() => {
    navigate(-1);
  }, [navigate]);

  const handleDropdownAction = useCallback(
    (action: NotificationDropdownOptions) => {
      if (action === NotificationDropdownOptions.MARK_ALL) {
        handleMarkAllAsReadNotifications();
      }

      if (action === NotificationDropdownOptions.REMOVE_ALL) {
        handleDismissAllNotifications();
      }

      if (action === NotificationDropdownOptions.SETTINGS) {
        handleTogglePushNotifications();
        handleUpdateNotificationSettings(
          !notificationSettings?.pushNotification,
        );
      }

      setToggleDropdown(false);
    },
    [
      handleMarkAllAsReadNotifications,
      handleDismissAllNotifications,
      handleTogglePushNotifications,
      handleUpdateNotificationSettings,
      notificationSettings,
    ],
  );

  useEffect(() => {
    if (isSuccessDismissAll || isSuccessDeleteById) {
      const message = isSuccessDismissAll
        ? t('notifications.toasts.removeAll')
        : t('notifications.toasts.delete');

      toast.success(message);
    }
  }, [isSuccessDismissAll, isSuccessDeleteById]);

  useEffect(() => {
    if (notificationSettings) {
      setTogglePushNotifications(notificationSettings.pushNotification);
    }
  }, [notificationSettings]);

  return {
    handleGoBack,
    notifications,
    toggleDropdown,
    handleDeleteById,
    notificationFilter,
    handleCloseDropdown,
    handleToggleDropdown,
    handleMarkAsReadById,
    handleDropdownAction,
    isLoadingNotifications,
    togglePushNotifications,
    handleChangeNotificationFilter,
  };
};
