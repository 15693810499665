import { t } from 'i18next';

import * as S from './Styles';

export function NotificationsEmptyState() {
  return (
    <S.Container>
      <svg viewBox="0 0 161 160">
        <path d="M136.215 120.24V126.43H24.7852V120.24L37.1652 107.86V70.715C37.1652 51.525 49.7302 34.625 68.1152 29.175C68.1152 28.555 68.1152 28 68.1152 27.38C68.1202 20.545 73.6602 15 80.5002 15C87.3402 15 92.8802 20.545 92.8802 27.38C92.8802 28 92.8802 28.555 92.8802 29.175C111.265 34.625 123.83 51.525 123.83 70.715V107.86L136.215 120.24ZM92.8802 132.62C92.8802 139.455 87.3402 145 80.5002 145C73.6602 145 68.1202 139.455 68.1202 132.62H92.8802Z" />
      </svg>

      <S.Title>{t('notifications.emptyState.title')}</S.Title>

      <S.Subtitle>{t('notifications.emptyState.subtitle')}</S.Subtitle>
    </S.Container>
  );
}
