import { useCallback } from 'react';
import SkorrApi from 'Services/SkorrApi/new';
import { IErrorObject } from 'Services/Utils/types';
import { logApiRequestError } from 'Services/Utils/Utils';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import {
  Notification,
  NotificationSettings,
  RawNotificationSettings,
  NotificationSettingsType,
  NotificationSettingsScope,
} from './types';

const { notifications } = SkorrApi;

export const useGetNotifications = () => {
  return useQuery(
    ['notifications'],
    async () => {
      try {
        const response = await notifications.getNotifications();
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      refetchOnWindowFocus: true,
      staleTime: 0,
      retry: 3,
    },
  );
};

export const useMarkAsReadById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: Notification) => {
      try {
        const response = await notifications.markAsReadById(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      },
    },
  );
};

export const useDeleteById = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: Notification) => {
      try {
        const response = await notifications.deleteById(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      },
    },
  );
};

export const useDismissAll = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      try {
        const response = await notifications.dismissAll();
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      },
    },
  );
};

export const useMarkAllAsReadNotifications = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      try {
        const response = await notifications.markAllAsReadNotifications();
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications'],
        });
      },
    },
  );
};

export const useUpdateNotificationSettings = () => {
  const queryClient = useQueryClient();

  return useMutation(
    async (params: NotificationSettings) => {
      try {
        const response = await notifications.updateNotificationSettings(params);
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      retry: 0,
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: ['notifications-settings'],
        });
      },
    },
  );
};

export const useGetNotificationSettings = () => {
  return useQuery(
    ['notifications-settings'],
    async () => {
      try {
        const response = await notifications.getNotificationSettings();
        return response;
      } catch (error) {
        logApiRequestError(error as IErrorObject);
        throw error;
      }
    },
    {
      select: useCallback((data: RawNotificationSettings[]) => {
        const pushNotificationSettings =
          data.find(
            notificationSettings =>
              notificationSettings.scope ===
                NotificationSettingsScope.NOTIFICATION &&
              notificationSettings.type ===
                NotificationSettingsType.PUSH_NOTIFICATION,
          )?.active ?? true;

        return {
          pushNotification: pushNotificationSettings,
        };
      }, []),
      refetchOnWindowFocus: true,
      staleTime: 0,
      retry: 3,
    },
  );
};
