import { t } from 'i18next';
import { convertNumberToHumanStringfiedFormat } from 'Services/Utils/Utils';
import { useDiscoverContext } from 'features/Discover/hooks/contexts/use-discover-context';

import DiscoverToolBarFilterButton from '../DiscoverToolBarFilterButton';
import DiscoverToolBarOrderByButton from '../DiscoverToolBarOrderByButton';
import DiscoverToolBarQuickActions from '../DiscoverToolBarQuickActions';
import DiscoverToolBarSearch from '../DiscoverToolBarSearch';
import * as S from './Styles';

const DiscoverToolBar = () => {
  const { publicUsersByFilter, selectedUsersCount } = useDiscoverContext();

  return (
    <S.ToolBar>
      <S.ToolBarContainer>
        <S.ToolBarTitle>{t('discover.title')}</S.ToolBarTitle>

        <DiscoverToolBarSearch />

        <S.ToolBarText1>
          {t('discover.showing')}
          <S.ToolBarText2>{` ${
            convertNumberToHumanStringfiedFormat(
              publicUsersByFilter?.total || 0,
            ) + t('discover.results')
          }`}</S.ToolBarText2>
        </S.ToolBarText1>

        <S.ToolBarDivider />

        <S.ToolBarText2>
          {convertNumberToHumanStringfiedFormat(selectedUsersCount()) +
            t('discover.entities')}
          <S.ToolBarText1>{t('discover.selected')}</S.ToolBarText1>
        </S.ToolBarText2>
      </S.ToolBarContainer>

      <S.ToolBarContainer>
        <DiscoverToolBarQuickActions />

        <S.ToolBarDivider />

        <DiscoverToolBarOrderByButton />

        <S.ToolBarDivider />

        <DiscoverToolBarFilterButton />
      </S.ToolBarContainer>
    </S.ToolBar>
  );
};

export default DiscoverToolBar;
