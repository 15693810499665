/* eslint-disable import/no-unresolved */
/* eslint-disable import/extensions */
import { DiscoverStack } from 'navigation/DiscoverStack';
import BenchmarksList from 'pages/Benchmark/BenchmarksList';
import SubmitBenchmark from 'pages/Benchmark/SubmitBenchmark';
import ViewBenchmark from 'pages/Benchmark/ViewBenchmark';
import RequestSocialAccount from 'pages/RequestSocialAccount';
import Listening from 'pages/Listening';
import ListeningTerm from 'pages/Listening/ListeningTerm';
import AdminPartnersList from 'pages/Admin/AdminPartnersList';
import AdminPartner from 'pages/Admin/AdminPartner';
import AdminAgentsList from 'pages/Admin/AdminAgentsList';
import AdminAgent from 'pages/Admin/AdminAgent';
import Notifications from 'pages/Notifications';
import { useEffect, useState } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import {
  Route,
  BrowserRouter as Router,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { ThemeProvider } from 'styled-components';
import CompanyEdit from 'pages/CompanyEdit/CompanyEdit';
import Agents from 'pages/Agents/Agents';
import ManagerSocialDataAccess from 'pages/ManagerSocialDataAccess/ManagerSocialDataAccess';

import {
  globalThemeDark,
  globalThemeLight,
  ThemeTogglerContext,
} from './css/ThemeGlobal';
/* Components */
import ErrorFallback from './Errors/ErrorBoundary';
import MenuNavbarV2 from './components/MenuNavbarV2/MenuNavbarV2';
/* Auth Pages */
import SignIn from './pages/SignIn/SignIn';
import SignUp from './pages/SignUp/SignUp';
/* Business/Brand Profile Pages */
import PublicUserMediaKit from './pages/PublicUserMediaKit/PublicUserMediaKit';
/* Other Pages */
import { isAuthenticated } from './Services/Utils/Utils';
import AccountActivation from './pages/AccountActivation/AccountActivation';
import AdditionalDataStep from './pages/AdditionalDataStep/AdditionalDataStep';
import BusinessProfile from './pages/BusinessProfile/BusinessProfile';
import BusinessProfiles from './pages/BusinessProfiles/BusinessProfiles';
import CreateMediaKitConfiguration from './pages/CreateMediaKitConfiguration/CreateMediaKitConfiguration';
import CreatePortfolioConfiguration from './pages/CreatePortfolioConfiguration/CreatePortfolioConfiguration';
import DeleteAccount from './pages/DeleteAccount/DeleteAccount';
import Discover from './pages/Discover/Discover';
import Profile from './pages/Profile/Profile';
import MediaKitConfigurationsList from './pages/MediaKitConfigurationsList/MediaKitConfigurationsList';
import MyAnalytics from './pages/MyAnalytics/MyAnalytics';
import PublicUserList from './pages/PublicUserList/PublicUserList';
import PublicUserListMembers from './pages/PublicUserListMembers/PublicUserListMembers';
import PublicUserLists from './pages/PublicUserLists/PublicUserLists';
import SocialAccountsManagement from './pages/SocialAccountsManagement/SocialAccountsManagement';

import 'line-awesome/dist/line-awesome/css/line-awesome.min.css';

function App() {
  const [themeGlobal, setThemeGlobal] = useState(() => {
    const darkMode = localStorage.getItem('darkMode') || 'false';
    if (darkMode === 'true') return globalThemeDark;
    return globalThemeLight;
  });

  const toggleTheme = () => {
    const darkMode = localStorage.getItem('darkMode') || 'false';
    if (darkMode === 'true') {
      setThemeGlobal(globalThemeLight);
      localStorage.setItem('darkMode', 'false');
    } else {
      setThemeGlobal(globalThemeDark);
      localStorage.setItem('darkMode', 'true');
    }
  };

  const AppProvider = ({ children }: any) => {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
      if (
        !(
          location.pathname === '/' ||
          location.pathname === '/signup' ||
          location.pathname === '/delete-account'
        ) &&
        !isAuthenticated()
      ) {
        navigate('/');
      }
    }, [location.pathname]);

    return <>{children}</>;
  };

  return (
    <>
      <ThemeProvider theme={themeGlobal}>
        <ThemeTogglerContext.Provider value={toggleTheme}>
          <ErrorBoundary FallbackComponent={ErrorFallback}>
            <Router>
              <AppProvider>
                <MenuNavbarV2>
                  <ToastContainer
                    position="top-center"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                  />
                  <Routes>
                    {/* Auth Routes */}
                    <Route path="/" element={<SignIn />} />
                    <Route path="/signup" element={<SignUp />} />
                    <Route path="/delete-account" element={<DeleteAccount />} />
                    <Route
                      path="/account-activation"
                      element={<AccountActivation />}
                    />
                    <Route
                      path="/additional-data-step"
                      element={<AdditionalDataStep />}
                    />

                    <Route path="/profile/edit/:id" element={<Profile />} />
                    <Route path="/profile/edit" element={<Profile />} />
                    <Route path="/profile/create" element={<Profile />} />

                    <Route
                      path="/social-accounts-management"
                      element={<SocialAccountsManagement />}
                    />

                    {/* Media Kit Routes */}
                    <Route path="/my-analytics" element={<MyAnalytics />} />
                    <Route
                      path="/user-media-kit/:publicUserId"
                      element={<PublicUserMediaKit />}
                    />
                    <Route
                      path="/view-media-kit-configurations-list"
                      element={<MediaKitConfigurationsList />}
                    />
                    <Route
                      path="/media-kit-configuration/create"
                      element={<CreateMediaKitConfiguration />}
                    />
                    <Route
                      path="/media-kit-configuration/edit/:id"
                      element={<CreateMediaKitConfiguration />}
                    />
                    <Route
                      path="/portfolio-configuration/create"
                      element={<CreatePortfolioConfiguration />}
                    />
                    <Route
                      path="/portfolio-configuration/edit/:id"
                      element={<CreatePortfolioConfiguration />}
                    />

                    {/* Business Account Routes */}
                    <Route
                      path="/business-profiles"
                      element={<BusinessProfiles />}
                    />
                    <Route
                      path="/business-profile/create"
                      element={<BusinessProfile />}
                    />
                    <Route
                      path="/business-profile/edit/:id"
                      element={<BusinessProfile />}
                    />
                    <Route path="/company-edit" element={<CompanyEdit />} />

                    {/* List Routes */}
                    <Route path="/lists" element={<PublicUserLists />} />
                    <Route path="/list/create" element={<PublicUserList />} />
                    <Route path="/list/edit/:id" element={<PublicUserList />} />
                    <Route
                      path="/list/members/:id"
                      element={<PublicUserListMembers />}
                    />

                    {/* Agents Routes */}
                    <Route path="/agents" element={<Agents />} />

                    <Route
                      path="/manager-data-access"
                      element={<ManagerSocialDataAccess />}
                    />

                    {/* Discover Stack */}
                    <Route path="/discover" element={<DiscoverStack />}>
                      <Route index element={<Discover />} />
                      <Route
                        path="user-media-kit/:publicUserId"
                        element={<PublicUserMediaKit />}
                      />
                    </Route>

                    {/* Benchmark Routes */}
                    <Route
                      path="/benchmarks-list"
                      element={<BenchmarksList />}
                    />
                    <Route
                      path="/create-benchmark"
                      element={<SubmitBenchmark />}
                    />
                    <Route
                      path="/update-benchmark/:id"
                      element={<SubmitBenchmark />}
                    />
                    <Route
                      path="/view-benchmark/:id"
                      element={<ViewBenchmark />}
                    />

                    {/* Request Social Account Routes */}
                    <Route
                      path="/request-social-account"
                      element={<RequestSocialAccount />}
                    />

                    {/* Listening Routes */}
                    <Route path="/listening" element={<Listening />} />
                    <Route
                      path="/listening/:term"
                      element={<ListeningTerm />}
                    />

                    {/* Admin Routes */}
                    <Route
                      path="/admin/partners-list"
                      element={<AdminPartnersList />}
                    />
                    <Route
                      path="/admin/create-partner"
                      element={<AdminPartner />}
                    />
                    <Route
                      path="/admin/edit-partner/:id"
                      element={<AdminPartner />}
                    />
                    <Route
                      path="/admin/agents-list/:id"
                      element={<AdminAgentsList />}
                    />
                    <Route
                      path="/admin/create-agent"
                      element={<AdminAgent />}
                    />
                    <Route
                      path="/admin/edit-agent/:id"
                      element={<AdminAgent />}
                    />

                    {/* Notification Routes */}
                    <Route path="/notifications" element={<Notifications />} />
                  </Routes>
                </MenuNavbarV2>
              </AppProvider>
            </Router>
          </ErrorBoundary>
        </ThemeTogglerContext.Provider>
      </ThemeProvider>
    </>
  );
}

export default App;
